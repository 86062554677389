<template>
    <div id="academie" class="d-flex align-items-stretch">
        <main-menu></main-menu>
        <div class="container">
            <user-profile></user-profile>
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>

import { mapState } from 'vuex';

// Components
import MainMenu    from './layouts/MainMenu.vue';
import UserProfile from './layouts/Profile.vue';

export default {
    components: {
        MainMenu,
        UserProfile
    },

    data () {
        return {

        };
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ])
    },

    mounted() {
        if(!this.user) {
            this.$router.push({name:'login'});
        }
    }
}
</script>
