<template>
    <div id="public-export">
        <h2 class='font-bold'>VOTE PUBLIC</h2>
        <div class="my-3">
            <p>Vous pouvez extraire les votes accumulés.</p>
        </div>
        <div class="d-flex justify-content-start">
            <button class="btn px-0 btn-link"
                @click="publicExport()"
                :disabled="sending"
            >
                <font-awesome-icon :icon="['fas','file-alt']" class="icon alt"/>
                EXTRAIRE LES DONNÉES
            </button>
        </div>
    </div>
</template>
<script>

import Alert from '@/helpers/Alert.js';
import {
    mapActions,
    mapState
} from 'vuex';

export default{
    data() {
        return {
            sending: false
        }
    },

    computed: {
        ...mapState('core/auth', [
            'user'
        ]),

    },

    methods: {
        ...mapActions('core/votes', [
            'publicResultsExport'
        ]),

        /**
         * Export the public vote results
         */
        publicExport () {
            Alert.confirmation(`Un document excel sera envoyé à ${this.user.attributes.email}`, "Extraire", '#E55A5A')
                .then((response) => {
                    if (response.isConfirmed) {
                        this.sending = true;
                        this.publicResultsExport()
                            .then(() => {
                                Alert.success(`Le document excel a été envoyé à ${this.user.attributes.email}`);
                            })
                            .catch(() => {
                                Alert.fail("Erreur lors de l'exportaion des membres");
                            })
                            .finally(() => {
                                this.sending = false;
                            })
                    }
                });
        }
    },
}
</script>
