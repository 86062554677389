<template>
    <div id="login" class="font-bold align-items-stretch">
        <div class="container">
            <div class="center-box row justify-content-center">
                <div class="login-box pt-4 pt-sm-5 p-3 p-sm-5 col-11 col-sm-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                    <div class="login-text pt-3 mb-5 text-center">
                        <h3> Connexion admin </h3>
                    </div>
                    <form class="login-form pt-2" v-on:submit.prevent>
                        <div class="form-group">
                            <label for="email" class="mb-2">Courriel <strong class="obligatory">*</strong></label>
                            <input type="text" name="email" class="form-control font-regular" id="email" v-model="username" placeholder="">
                            <div class="invalid-feedback">
                                {{ errors.first('email') }}
                            </div>
                            <label for="password" class="mb-2 mt-4">Mot de passe <strong class="obligatory">*</strong></label>
                            <input type="password" name="password" class="form-control font-regular" id="password" v-model="password" placeholder="">
                            <div class="invalid-feedback">
                                {{ errors.first('password') }}
                            </div>
                        </div>
                        <div class="submit-button mt-4 text-end">
                            <button type="submit" class="btn btn-primary mt-2" :disabled="loading" @click="login()">
                                <span v-if="loading" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>&nbsp;
                                Soumettre
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {
    mapState,
    mapActions
} from 'vuex';

export default {
    data () {
        return {
            loading: false,
            username: '',
            password: ''
        };
    },
    computed: {
        ...mapState('core/auth', [
            'user',
            'userLink'
        ]),
    },
    methods: {
        ...mapActions('core/auth', {
            coreAuthLogin:        'login',
            getAuthenticatedUser: 'getAuthenticatedUser'
        }),

        ...mapActions('core/ballot', {
            checkStartedBallot: 'getStartedBallot',
        }),

        /**
         * Login
         * - check if username and password input is provided
         * - Ajax request to login to the system
         */
        login () {
            this.loading = true;

            // Login via the API
            this.coreAuthLogin({
                email:    this.username,
                password: this.password
            })
                .then(() => {
                    this.$session.start();
                    this.$session.set('validAdminToken', true);
                    this.getAuthenticatedUser()
                        .then(() => {

                            this.checkStartedBallot()
                                .then(() => {
                                    this.$router.push({name:'search-categories'});
                                });

                        })
                        .finally(() => {
                            this.loading = false;
                        });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>
