<template>
    <div id="form-categories">
        <div class="header mb-4">
            <h2 class="font-bold" v-if="newCategory"> NOUVELLE CATÉGORIE </h2>
            <h2 class="font-bold" v-else> {{this.singleCategory.attributes.name}} </h2>
        </div>
        <div class="form-section mb-3">
            <div class="d-flex justify-content-between mb-2">
                <div class="align-self-center">
                    <h5 class="font-bold m-0"> Détails </h5>
                </div>
                <div class="submit-section d-flex">
                    <div
                        class="ballot-status d-flex py-2 me-3 px-4"
                        :class="{
                            'checked':  category.results_final,
                            'disabled': Object.keys(ballots).length === 0
                        }"
                        v-popover="{ name: 'info' }"
                    >
                        <input
                            type="checkbox"
                            name="status"
                            id="status"
                            class="status form-check-input me-4 mt-0"
                            v-model="category.results_final"
                            :disabled="Object.keys(ballots).length === 0 || ballotStarted || !canUpdate"
                            @click="updateCategoryResults()"
                        >
                        <p class="m-0 align-self-center font-bold"> Résultats Certains </p>
                    </div>
                    <popover name="info" event="hover" :width="400">
                        <p> Veuillez cocher quand un résultat est certain. La catégorie, ses informations et ses résultats resteront figés. </p>
                        <p class="m-0"> Une catégorie avec des résultats certains  ne sera pas éligible  pour les scrutins supplémentaires. </p>
                    </popover>
                    <button type="submit"
                        class="btn btn-secondary px-5"
                        @click="newCategory ? createNewCategory() : updateCategory()"
                        :disabled="ballotStarted || !btnDisabled || !canUpdate"
                    >
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                        {{ newCategory ? 'Sauvegarder' : 'Mettre à jour' }}
                    </button>
                </div>
            </div>
            <div id="add-category-form" class="info-section text-center row mt-5 mb-5">
                <div class="inputs-section col-7 row flex-container">
                    <div class="col-4 text-start mb-4" :class="{ 'align-self-center': !errors.has('name') }">
                        <label for="name" class="font-bold">Nom de la catégorie <span class="obligatory">*</span></label>
                    </div>
                    <div class="col-8 mb-4">
                        <input
                            type="text"
                            class="form-control"
                            name="name"
                            id="name"
                            placeholder="inscrire le nom de la catégorie"
                            v-model="category.name"
                            v-validate="'required'"
                            data-vv-as="Nom de la catégorie"
                            :class="{'is-invalid' : errors.has('name')}"
                            @change="btnDisabled = true"
                        >
                        <div class="invalid-feedback" v-if="errors.has('name')">
                            {{ errors.first('name') }}
                        </div>
                    </div>
                    <div class="col-4 text-start mb-4">
                        <label class="font-bold">Qui peut voter <span class="obligatory">*</span></label>
                    </div>
                    <div class="col-8 mb-4">
                        <div class="d-flex mb-2">
                            <div class="me-5">
                                <input
                                    type="checkbox"
                                    class="me-3 form-check-input"
                                    name="whoCanVote"
                                    id="member"
                                    :class="{'is-invalid' : errors.has('whoCanVote')}"
                                    v-model="category.available_members"
                                    v-validate="!category.available_jury ? 'required' : ''"
                                    value="member"
                                    data-vv-as="Qui peut voter"
                                    @change="btnDisabled = true"
                                >
                                <label for="member"> Membres </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    class="me-3 form-check-input"
                                    name="whoCanVote"
                                    id="jury"
                                    :class="{'is-invalid' : errors.has('whoCanVote')}"
                                    v-model="category.available_jury"
                                    v-validate="!category.available_members ? 'required' : ''"
                                    value="jury"
                                    data-vv-as="Qui peut voter"
                                    @change="updateJuryList()"
                                >
                                <label for="jury" > Jury </label>
                            </div>
                        </div>
                        <div class="invalid-feedback" v-if="errors.has('whoCanVote')">
                            {{ errors.first('whoCanVote') }}
                        </div>
                        <div>
                            <select
                                class="form-select"
                                aria-label="Qui peut voter"
                                id="who-can-vote"
                                name="juryName"
                                v-model="juryId"
                                v-validate="category.available_jury ? 'required' : ''"
                                :class="{'is-invalid' : errors.has('juryName')}"
                                data-vv-as="Nom du jury"
                                :disabled="!category.available_jury"
                                @change="btnDisabled = true"
                            >
                                <option :value="null" selected> Choisir un jury </option>
                                <option :value="jury.id" v-for="jury in listJuries" :key="jury.id">{{jury.attributes.name}}</option>
                            </select>
                            <div class="invalid-feedback">
                                {{ errors.first('juryName') }}
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-start" :class="{ 'align-self-center': !errors.has('type') }">
                        <label for="type" class="font-bold">Type de vote <span class="obligatory">*</span></label>
                    </div>
                    <div class="col-8">
                        <select
                            class="form-select"
                            aria-label="Type de vote"
                            id="type"
                            name="type"
                            v-model="category.voting_type"
                            v-validate="'required'"
                            data-vv-as="Type de vote"
                            :class="{'is-invalid' : errors.has('type')}"
                            @change="voteTypeChanged(category.voting_type)"
                        >
                            <option value="">Choisir un type de vote</option>
                            <option value="one_choice">un seul vote</option>
                            <option value="criterias">par critère</option>
                            <option value="three_choices">3 choix</option>
                        </select>
                        <div class="invalid-feedback" v-if="errors.has('type')">
                            {{ errors.first('type') }}
                        </div>
                    </div>
                    <div id="criteria-form" v-if="category.voting_type === 'criterias'" class="info-section">
                        <div class="criteria-section col-12 mt-4 d-flex" v-for="(criteria, index) in criterias" :key="criteria.index">
                            <div class="col-3 text-end pe-4 align-self-center">
                                <label :for="`criteria-${index}`" class="font-bold"> {{ `Critère ${index + 1}` }} <span class="obligatory">*</span></label>
                            </div>
                            <div class="criteria col-8 ps-2" >
                                <input
                                    type="text"
                                    :name="`criteria-${index}`"
                                    :id="`criteria-${index}`"
                                    class="form-control"
                                    :class="{'is-invalid' : errors.has(`criteria-${index}`)}"
                                    v-validate="'required'"
                                    data-vv-as="Critère"
                                    v-model="criteria.text"
                                    @blur="recalculateCriterias(index, criteria.text)"
                                    @change="btnDisabled = true"
                                >
                                <div class="invalid-feedback" v-if="errors.has(`criteria-${index}`)">
                                    {{ errors.first(`criteria-${index}`) }}
                                </div>
                            </div>

                            <div class="col-1 align-self-center">
                                <router-link :to="{ name: '' }" @click.native="deleteCriteria(index)">
                                    <font-awesome-icon :icon="['fas','times']" class="delete-font"/>
                                </router-link>
                            </div>

                        </div>
                        <div class="links col-10 mt-4">
                            <div class="add-data d-flex justify-content-center">
                                <router-link :to="{ name: '' }" class="m-0 pe-4 d-flex justify-content-center" @click.native="addCriteria()">
                                    <font-awesome-icon class="plus align-self-center me-2" icon="fas fa-plus" />
                                    <p class="m-0 font-bold align-self-center"> ajouter un critère </p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="options-panel" class="col-5 d-flex flex-row-reverse">
                    <div class="options-section p-4 text-start align-self-start">
                        <div class="title mb-3">
                            <h4 class="font-bold"> Options </h4>
                        </div>
                        <div class="mb-3">
                            <input type="checkbox"
                                class="me-3 form-check-input"
                                name="special"
                                id="special"
                                v-model="category.special"
                                @change="btnDisabled = true"
                            >
                            <label for="special" class="d-inline"> Catégorie spéciale </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                v-model="linksSup"
                                class="me-3 form-check-input"
                                name="infos-links"
                                id="infos-links"
                                @click="updateLinksSup()"
                                @change="btnDisabled = true"
                            >
                            <label for="infos-links" class="d-inline"> Ajouter un lien pour information supplémentaire </label>
                        </div>
                        <div class="link-sup px-4" v-if="linksSup">
                            <div class="mt-3 mb-3">
                                <label for="link-1"> Lien 1 </label>
                                <input type="text"
                                    class="form-control mb-2"
                                    :class="{'is-invalid' : errors.has('link-1')}"
                                    name="link-1"
                                    id="link-1"
                                    placeholder="nom ou texte du lien"
                                    v-model="category.url_label"
                                    v-validate="'required'"
                                    data-vv-as="lien 1"
                                    @change="btnDisabled = true"
                                >
                                <div class="invalid-feedback">
                                    {{ errors.first('link-1') }}
                                </div>
                                <input type="url"
                                    class="form-control"
                                    :class="{'is-invalid' : errors.has('url-1')}"
                                    name="url-1"
                                    id="url-1"
                                    placeholder="inscrire un URL"
                                    v-model="category.url"
                                    v-validate="'required'"
                                    data-vv-as="URL"
                                >
                                <div class="invalid-feedback" :class="{'display : inline' : btnDisabled}">
                                    {{ errors.first('url-1') }}
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="link-2"> Lien 2 (optionel) </label>
                                <input type="text"
                                    class="form-control mb-2"
                                    name="link-2"
                                    id="link-2"
                                    placeholder="nom ou texte du lien"
                                    v-model="category.optional_label"
                                    v-validate="category.optional_url ? 'required' : ''"
                                    :class="{'is-invalid' : errors.has('link-2')}"
                                    data-vv-as="Lien 2"
                                    @change="btnDisabled = true"
                                >
                                <div class="invalid-feedback">
                                    {{ errors.first('link-2') }}
                                </div>
                                <input type="url"
                                    class="form-control"
                                    name="url-2"
                                    id="url-2"
                                    placeholder="inscrire un URL"
                                    v-model="category.optional_url"
                                    v-validate="category.optional_label ? 'required' : ''"
                                    :class="{'is-invalid' : errors.has('url-2')}"
                                    data-vv-as="URL pour Lien 2"
                                    @change="btnDisabled = true"
                                >
                            </div>
                            <div class="invalid-feedback pb-1" :class="{'display : inline' : btnDisabled}">
                                {{ errors.first('url-2') }}
                            </div>
                            <div>
                                <p class="m-0">
                                    Liens seront visibles dans l'interface de vote en haut de la catégorie. Si vide, lien non visible.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <div class="results text-start">
                <p class="m-0 font-bold">
                    {{ `Candidatures (${tableBody.length}) et résultats` }}
                    <button
                        class="btn trigger-slide-form ps-0 mb-1 edit-table"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        :disabled="newCategory === true || !canUpdate"
                        @click="updateSlideForm('table', category.headings)"
                        v-if="!ballotStarted"
                    >
                        <font-awesome-icon :icon="['fas','pen']" class="align-self-center ms-2 icon alt"/>
                    </button>
                </p>
            </div>
            <div class="links d-flex">
                <div class="add-data d-flex">
                    <button
                        class="btn btn-link trigger-slide-form pe-0"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        @click="updateSlideForm('candidacy',{})"
                        :disabled="category.headings === null || newCategory === true || !canUpdate"
                        v-if="!ballotStarted"
                    >
                        <font-awesome-icon :icon="['fas','plus']" class="icon alt"/>
                        &nbsp; AJOUTER UNE CANDIDATURE
                    </button>
                </div>
            </div>
        </div>
        <gold-table
            :header="tableHeader"
            :data="tableBody"
            v-on:editTable="updateNominations($event)"
        >
        </gold-table>
        <slide-form
            :type="slideFormType"
            :model="modelToEdit"
            :categoryId="singleCategory.id"
            v-on:saveHeadings="updateHeadings($event)"
            v-on:addNominations="addNominations()"
        >
        </slide-form>
    </div>
</template>
<script>

// helper
import Alert        from '@/helpers/Alert.js';
import { EventBus } from '@/helpers/EventBus.js';
import {
    mapActions,
    mapState,
    mapGetters
} from 'vuex';

import SlideForm from '../../layouts/SlideForm.vue';
import GoldTable from '../../layouts/GoldTable.vue';

export default {
    components: {
        SlideForm,
        GoldTable
    },

    data () {
        return {
            newCategory:      true,
            linksSup:         false,
            loading:          false,
            modelToEdit:      {},
            slideFormType:    'table',
            criterias:        [],
            tableHeader:      [],
            tableBody:        [],
            category:      {
                voting_type:       '',
                special:           false,
                available_jury:    false,
                available_members: false,
                new_criterias:     [],
                results_final:     false,
                optional_label:    '',
                optional_url:      ''
            },
            juryId:           null,
            btnDisabled:      false,
            voting_type:      'one_choice',
            special:          false,
            available_jury:   false,
            new_criterias:    [],
            results_final:    false
        }
    },

    watch: {
        /**
         * Update new_criterias, attribute of category, according to creterias edited by user
         *
         */
        criterias: function (value) {
            this.category.criterias = [];
            value.forEach((criteria) => {
                this.category.criterias.push({name: criteria.text});
            });
        }
    },

    computed: {
        ...mapState('core/jury', {
            listJuries: state => state.juries
        }),
        ...mapState('core/category', [
            'singleCategory'
        ]),
        ...mapGetters('core/category', [
            'nominations', 'jury'
        ]),
        ...mapState('core/ballot', [
            'ballotStarted',
            'ballots'
        ]),
        ...mapGetters('core/auth', [
            'canUpdate'
        ]),
    },

    /**
     * Load category form store to fill the form with data of category to edit
     *
     * @return void
     */
    mounted () {
        if(Object.keys(this.listJuries).length === 0) {
            this.getjuries();
        }

        if(Object.keys(this.singleCategory).length !== 0) {
            this.setSingleCategory();
        }

        // Delete nomination
        EventBus.$on('deleteRow', (id) => {
            this.delete({id: id}).then(() => {
                this.tableBody = this.tableBody.filter(nomination => nomination.id !== id);
                Alert.success('Candidature supprimé avec succès');
                this.getSingleCategory({id: this.singleCategory.id}).then(() => {
                    this.showNominations();
                });
            });
        });
    },

    methods: {
        ...mapActions('core/category', {
            create:            'create',
            update:            'update',
            getSingleCategory: 'getSingleCategory'
        }),

        ...mapActions('core/nominations', {
            updateNomination: 'update',
            delete: 'delete'
        }),

        ...mapActions('core/jury', {
            getjuries: 'index'
        }),

        /**
         * Set single category
         */
        setSingleCategory () {
            let attributes = JSON.parse(JSON.stringify(this.singleCategory.attributes));
            this.category = attributes;
            this.linksSup = this.category.url !== null;
            this.newCategory = false;
            this.criterias = [];
            if(attributes.criterias) {
                attributes.criterias.forEach(criteria => this.criterias.push({text: criteria.name}));
            }

            if (this.jury) {
                this.juryId = this.jury.id;
            }

            // If category has headings populate gold table
            if (this.category.headings !== null) {
                this.getTableHeader();
                this.showNominations();
            }
        },

        /**
         * Update the criterias list if the vote type is changed
         *
         * @param   {string}  type  the new category vote type
         * @returns  void
         */
        voteTypeChanged (type) {
            if(type === 'criterias') {
                this.addCriteria();
            } else {
                this.criterias = [];
            }
            this.btnDisabled = true;
        },

        /**
         * Create new category
         *
         * @return void
         */
        createNewCategory () {
            this.loading = true;
            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.loading     = false;
                    this.btnDisabled = false;
                    return;
                }
                    this.create({
                        category: this.category,
                        juryId:   this.juryId
                    })
                        .then(() => {
                            Alert.success('Catégorie ajoutée avec succès');
                            this.$router.push({name:'search-categories'});
                        })
                        .finally(() => {
                            this.loading     = false;
                            this.btnDisabled = false;
                        });

            });
        },

        /**
         * Update a nomination
         *
         * @param   {object}    payload    the new nomination data
         * @return  void
         */
        updateNominations (payload) {
            if (payload.type === 'url' && !this.isValidURL(payload.text)) {
                Alert.fail("Le format de l'URL n'est pas valide");
                this.showNominations();
                return;
            }

            let attributes = {
                 id:         payload.LineId,
                 categoryId: this.singleCategory.id,
            };

            if (payload.type === 'url') {
                // Update nomination URL
                attributes.url = payload.line.columns.find(heading => heading.type === 'url').text
            } else {
                // Update the nomination headings values.
                let newHeadings = [];
                payload.line.columns.filter(heading => heading.type === 'text').forEach(heading => {
                    newHeadings.push(heading.text);
                });
                attributes.headings = newHeadings;
            }
            this.updateNomination(attributes)
                    .then(() => {
                        this.getSingleCategory({id: this.singleCategory.id}).then(() => {
                            this.showNominations();
                            Alert.success('Candidature modifier avec succès');
                        });
                    })
        },

        /**
         * After the Update of the headings.
         *
         * @param  {array}  headings   the new headings list
         * @return   void
         */
        updateHeadings (headings) {
            this.category.headings = [];
            this.category.headings = headings
            this.getSingleCategory({id: this.singleCategory.id}).then(() => {
                this.getTableHeader();
                this.showNominations();
            });
        },

        /**
         * After the creation of new nomination
         *
         * @return   void
         */
        addNominations () {
            this.getSingleCategory({id: this.singleCategory.id}).then(() => {
                this.showNominations();
            });
        },

        /**
         * Check if a sting is a valide URL
         *
         * @param   {string}   string
         * @returns boolean
         */
        isValidURL (string) {
            if (!string) {
                return true;
            }

            let url_string;

            try {
                url_string = new URL(string);
            } catch (_) {
                return false;
            }

            return url_string.protocol === "http:" || url_string.protocol === "https:";
        },

        /**
         * Put a category like certain or uncertain
         */
        updateCategoryResults () {
            let newResultStatus = !this.category.results_final;
            let payload = {
                id: this.singleCategory.id,
                attributes: {
                    results_final: newResultStatus
                }
            }
            this.sendUpdateRequest(payload);
        },

        /**
         * Update category
         *
         * @return void
         */
        updateCategory () {
            this.$validator.validate().then((valid) =>{
                if (!valid) {
                    return;
                }

                this.loading = true;
                let payload = {
                    id:         this.singleCategory.id,
                    attributes: this.category,
                    juryId:     this.juryId
                }
                this.sendUpdateRequest(payload);
            });
        },

        /**
         * Send update category request
         *
         * @param   {object}  payload  the new data
         * @return  void
         */
        sendUpdateRequest (payload) {
            this.update(payload)
                .then(() => {
                    this.getSingleCategory({id: this.singleCategory.id}).then(() => {
                        Alert.success('Catégorie mise à jour avec succès');
                        this.btnDisabled = false;
                        this.getTableHeader();
                        this.showNominations();
                    })
                })
                .catch((error) => {
                    Alert.fail(error.response.data.errors[0].detail);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Update the section of links sup
         */
        updateLinksSup () {
            if (this.linksSup) {
                this.category.url            = null;
                this.category.url_label      = null;
                this.category.optional_url   = null;
                this.category.optional_label = null;
            }
            this.linksSup = !this.linksSup;
        },

        /**
         * Detele criteria from table
         *
         * @param    {int}    index    the index
         */
        deleteCriteria (index) {
            this.criterias.splice(index, 1);
            this.btnDisabled = true;
        },

        /**
         * Add new criteria element
         */
        addCriteria () {
            this.criterias.push({ text: '' });
        },

        /**
         * Update SlideForm props dependeing on the button clicked
         *
         * @param {string} type the type of the slideForm ('table' or 'candidacy')
         * @param {object} model the model to edit in the slideForm.
         */
        updateSlideForm (type, model) {
            let titles = {};
            if (model !== null && model !== undefined) {
                for (let i = 0; i < model.length; i++) {
                titles = {
                        text: this.category.headings[i]
                    }
                }
            }
            this.slideFormType = type;
            this.modelToEdit   = type === 'type' ? {} : titles;
        },

        /**
         * Update the jury of this category
         *
         * @return   void
         */
        updateJuryList () {
            if(!this.category.available_jury) {
                this.juryId = null;
            }
            this.btnDisabled = true;
        },

        /**
         * Update the arry of criterias
         * @param {int} index the index in the criterias table
         * @param {text} text the value of criteria
         * @return {void}
         */
        recalculateCriterias(index, text) {
            this.criterias.splice(index, 1, {'text': text});
        },

        /**
         * Populate Table header
         *
         * @return void
         */
        getTableHeader () {
            this.tableHeader = [];
            this.category.headings.forEach((element, index) => {
                this.tableHeader.push({
                    id:       index + 1,
                    text:     element,
                    position: 'start',
                    editable: true
                });
            });
            this.tableHeader.push({ id: this.category.headings.length + 1, text: 'Extrait', position: 'center', editable: true  });

            if (this.category.available_jury && this.category.available_members) {
                this.tableHeader.push(
                    { id: this.category.headings.length + 2, text: 'Résultats Jury',    position: 'center', editable: false },
                    { id: this.category.headings.length + 3, text: 'Résultats Membre',  position: 'center', editable: false }
                );
            } else {
                this.tableHeader.push(
                    { id: this.category.headings.length + 2, text: 'Résultats', position: 'center', editable: false }
                );
            }

            let number = (this.category.available_jury && this.category.available_members) ? 4 : 3;
            this.tableHeader.push({ id: this.category.headings.length + number, text: 'Action', position: 'center', editable: false });
        },

        /**
         * Populate Table body
         *
         * @return void
         */
        showNominations () {
            if (this.nominations === undefined) {
                return;
            }
            this.tableBody = [];
            this.nominations.forEach(nomination => {
                let columns = [];
                let number = (this.category.available_jury && this.category.available_members) ? 4 : 3;
                nomination.attributes.headings.forEach((heading, index) => {
                    if (index < (this.tableHeader.length - number)) {
                        columns.push({
                            text:       heading,
                            position:   'start',
                            type:       'text',
                            editable:   true
                        });
                    }
                });
                columns.push(
                    {
                        text:       nomination.attributes.url === null ? '' : nomination.attributes.url,
                        position:   'center',
                        type:       'url',
                        editable:   true
                    }
                );
                if (this.category.available_jury && this.category.available_members) {
                    let voteResults = ['jury_results', 'member_results'];
                    voteResults.forEach((element) => {
                        columns.push(
                            {
                                text:       nomination.attributes[element] === null ? 0 : nomination.attributes[element],
                                position:   'center',
                                type:       'integer',
                                editable:   false
                            }
                        );
                    })
                } else {
                    let voteResults = this.category.available_jury ? 'jury_results' : 'member_results';
                    columns.push(
                        {
                            text:       nomination.attributes[voteResults] === null ? 0 : nomination.attributes[voteResults],
                            position:   'center',
                            type:       'integer',
                            editable:   false
                        }
                    )
                }
                this.tableBody.push({
                    id:      nomination.id,
                    columns: columns
                });
            });
        }
    },

    beforeDestroy () {
        EventBus.$off('deleteRow');
    }
}
</script>
