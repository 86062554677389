<template>
    <div id="black-table">
        <table class="table table-bordered align-middle">
            <thead class="dark-header">
                <tr>
                    <th v-for="title in header" :key="title.id" scope="col" :class="`text-${title.position}`">{{ title.text }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="line in data" :key="line.id" class="line">
                    <td v-for="column in line.columns"
                        :key="column.id"
                        :class="`text-${column.position}`"
                        @click="editModel(line.id)"
                    >
                        <input class="form-check-input" type="checkbox" v-if="column.type === 'checkbox'" :checked="line.checkboxChecked" onclick="return false">
                        <p class="m-0" v-if="column.type === 'text'" :class="{ 'text-success':column.text==='Terminé' }"> {{ column.text }} </p>
                        <font-awesome-icon :icon="['fas','star']" class="star icon alt" v-if="column.type === 'star' && line.marked"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

export default {
    props: {
        /**
         * This component is set to create a dynamic Table.
         * He has Header and Data like props:
         *
         * @prop     {Array, Object}     Header    The data to set on the header of the table
         * @example  { id: (int) the ID, text: (String) text to be show, position: (String) Can be center, start or end }
         *
         * @prop     {Array, Object}      Data      The data to show in this dynamic table.
         * @example {
         *      id:              (int) the line ID,
         *      marked:          (Boolean) To show the star or not
         *      checkboxChecked: (Boolean) To check the checkbox or not,
         *      columns:         (Array of objects) object to be shown in the column can be text, star or checkbox, example: [
         *           {
         *              text:     (String) can be null if the type is star or checkbox,
         *              position: (String) Can be center, start or end,
         *              type:     (String) text, star or checkbox
         *           },
         *      ]
         * }
         *
         */
        header: {
            type: [Array, Object]
        },
        data: {
            type: [Array, Object]
        }
    },
    data () {
        return {
        };
    },
    methods: {
        /*
         * Emit event when a cell in the black table is clicked
         * Using the event, we send the current model's id we are editing to SlideForm component
         * @param  {string}  payload the current model's we're editing
         */
        editModel(payload) {
            this.$emit('editCurrentModel', payload);
        }
    }
}
</script>>
