import Api       from '@/helpers/Api.js';
import qs        from 'qs';
import normalize from 'json-api-normalizer';
const resource  = 'api/academie/v1/admins';

// Import mutations
import {
    SET_USERS, ADD_USER, UPDATE_USER, REMOVE_USER,
} from './mutation-types.js';

const state = () => ({
    users:      {},
    singleUser: {}
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Get all users.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   payload     The payload (filters)
     *
     * @return     {Promise}
     */
    index: (context,  payload) => {
        const urlParams = {};
        if (payload) {
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, {camelizeKey: false});
                    context.commit(SET_USERS, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * Creates a new User.
     *
     * @param      {Object}  context      The context
     * @param      {Object}  user     The user to be stored
     *
     * @return     {Promise}
     */
    create: (context, user) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type': 'admins',
                    'attributes': {
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        role: user.role,
                    },
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, {camelizeKey: false});
                    context.commit(ADD_USER, normalizedData.admins);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    },
    /**
     * Delete user by id
     *
     * @param      {Object}   context                  The context
     * @param      {string}   id                       The user id
     *
     * @return     {Promise}
     */
    delete: (context, id) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    context.commit(REMOVE_USER, id);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * Update du membre
     *
     * @param      {Object}   context                  The context
     * @param      {string}   user                   The user
     *
     * @return     {Promise}
     */
    update: (context, user) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${user.id}`, {
                'data': {
                    'id': user.id,
                    'type': 'admins',
                    'attributes': {
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        role: user.role,
                    },
                }
            })
                .then((response) => {
                    const {id} = user;
                    const normalizedData = normalize(response.data, {camelizeKey: false});
                    context.commit(UPDATE_USER, 
                        {id, ...normalizedData.admins[id]}
                    );
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    }
};

// Mutations
const mutations = {
    [SET_USERS]: (state, payload) => {
        state.users = payload.admins ? payload.admins : {};
    },
    [ADD_USER]: (state, payload) => {
        state.users[payload.id] = payload;
    },
    [UPDATE_USER]: (state, payload) => {
        state.users[payload.id] = { ...state.users[payload.id], ...payload };
    },
    [REMOVE_USER]: (state) => {
        delete state.users.id;
    },
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
