import Vue       from 'vue';
import VueRouter from 'vue-router';
import Routes    from '@/routes/routes.js';
import coreStore from '@/store';

// Routing
Vue.use(VueRouter);

// Catch errors from VueRouter.push()
const originalPush       = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }

    return originalPush.call(this, location).catch((err) => {
        console.warn(err);
    });
};

// Create the router
const router = new VueRouter({
    mode:   'history',
    base:   process.env.VUE_APP_BASE_URL,
    routes: Routes
});

// Set Admin like default title and add meta title if exist
const DEFAULT_TITLE = 'Admin';
router.afterEach((to) => {
    document.title = typeof to.meta.title !== 'undefined'
        ? `${DEFAULT_TITLE} - ${to.meta.title}`
        : DEFAULT_TITLE;
});

// Before each redirection check if an authenticated voter exists.
router.beforeEach((to, from, next) => {
    if (coreStore.state.core.auth.user === null && to.name !== 'login') {
        next({ name: `login` });
    } else if (coreStore.state.core.auth.user !== null && Vue.prototype.$session.exists() && to.name === 'login') {
        next({ name: `search-categories` });
    } else {
        next();
    }
});

export default router;
