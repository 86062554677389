import Api from '@/helpers/Api.js';

const resource_csrf_cookies  = '/sanctum/csrf-cookie';
const resource               = '/api/auth/v1/';
const ROLE_ADMIN             = 'admin';

// Import mutations
import {
    SET_AUTH_USER,
    SET_AUTH_USER_LINK,
    REMOVE_AUTH_USER,
    REMOVE_AUTH_USER_LINK
} from './mutation-types.js';

const state = () => ({
    user:     null,
    userLink: null
});

// Getters
const getters = {
    /**
     * Check if the user can update ressources (only possible for admin)
     *
     * @return   boolean
     */
    canUpdate (state) {
        return (state.user && state.user.attributes.role === ROLE_ADMIN);
    }
};


// Actions
const actions = {
    /**
     * Send user's login information.
     *
     * @param      {Object}  context.commit    The commit
     * @param      {string}  payload.email     The email
     * @param      {string}  payload.password  The password
     *
     * @return     {Promise}
     */
    login: (context, { email, password }) => {
        return new Promise((resolve, reject) => {
            Api.get(resource_csrf_cookies).then(() => {
                Api.post(`${resource}login`, {
                        'data': {
                            'attributes': {
                                email,
                                password
                            }
                        }
                    })
                    .then(response => {
                        context.commit(SET_AUTH_USER_LINK, response.data.data.links.user);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    },
    /**
     * Gets the authenticated user.
     *
     * @param      {Object}   context  The context
     *
     * @return     {Promise}  The authenticated user.
     */
    getAuthenticatedUser: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(context.state.userLink)
                .then((response) => {
                    context.commit(SET_AUTH_USER, response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * Logout user.
     *
     * @param      {Object}  context.commit    The commit
     *
     * @return     {Promise}
     */
    logout: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}logout`)
                .then((response) => {
                    context.commit(REMOVE_AUTH_USER);
                    context.commit(REMOVE_AUTH_USER_LINK);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

// Mutations
const mutations = {
    [SET_AUTH_USER]: (state, payload) => {
        state.user = payload;
    },
    [SET_AUTH_USER_LINK]: (state, payload) => {
        state.userLink = payload;
    },
    [REMOVE_AUTH_USER]: (state) => {
        state.user = null;
    },
    [REMOVE_AUTH_USER_LINK]: (state) => {
        state.userLink = null;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
