import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import qs        from 'qs';

const resource  = 'api/academie/v1/ballots';

// Import mutations
import {
    SET_BALLOTS, SET_BALLOT_STARTED, SET_BALLOT_ENDED, ADD_BALLOT
} from './mutation-types.js';

const state = () => ({
    ballots:       {},
    currentBallot: {},
    ballotStarted: false
});

// Getters
const getters = {
};

// Actions
const actions = {

    /**
     * Load ballots and check if there are an active ballot
     *
     * @param      {Object}  context      The context
     *
     * @return     {Promise}
     */
    index: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`)
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });
                    context.commit(SET_BALLOTS, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get the started ballot if exists.
     *
     * @param      {Object}  context      The context
     *
     * @return     {Promise}
     */
    getStartedBallot: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`, {
                params: {
                    filter: {
                        started: true
                    }
                },
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKey: false });
                    context.commit(SET_BALLOT_STARTED, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Creates a ballot
     *
     * @param      {Object}  context      The context
     * @param      {Object}  category     The category to be stored
     *
     * @return     {Promise}
     */
    create: (context,  ballot) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type': 'ballots',
                    'attributes': ballot
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_BALLOT, normalizedData);
                    resolve(normalizedData.ballots);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Start a ballot
     *
     * @param      {Object}  context      The context
     * @param      {String}  id          The ballot's id
     *
     * @return     {Promise}
     */
    start: (context, id) => {
        return new Promise((resolve, reject) => {
            Api.put(`${resource}/${id}/start`)
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_BALLOT_STARTED, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Stop a ballot
     *
     * @param      {Object}  context      The context
     * @param      {String}  id           The ballot's id
     *
     * @return     {Promise}
     */
    stop: (context, id) => {
        return new Promise((resolve, reject) => {
            Api.put(`${resource}/${id}/stop`)
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_BALLOT_ENDED);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_BALLOTS]: (state, payload) => {
        state.ballots = payload.ballots ? payload.ballots : {};
    },
    [ADD_BALLOT]: (state, payload) => {
        state.ballots = { ...state.ballots, ...payload.ballots };
    },
    [SET_BALLOT_STARTED]: (state, payload) => {
        if (payload.ballots) {
            state.currentBallot = payload.ballots;
            state.ballotStarted = true;
        } else {
            state.currentBallot = {};
            state.ballotStarted = false;
        }
    },
    [SET_BALLOT_ENDED]: (state) => {
        state.currentBallot = {};
        state.ballotStarted = false;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
