<template>
    <div id="email-sender">
        <h2 class='font-bold'>ENVOI DE COURRIELS</h2>
        <div class="mt-5">
                <form>
                    <div class="row">
                        <div class="col-md-9 my-3">
                            <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label font-bold">Objet <span class="obligatory">*</span></label>
                                <div class="col-sm-9">
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="subject"
                                        v-validate="'required'"
                                        name="objet"
                                        :class="{ 'is-invalid': submitted && errors.has('objet') }"
                                    >
                                    <div class="invalid-feedback" v-if="submitted">
                                        {{ errors.first('objet') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label font-bold">Destinaires <span class="obligatory">*</span></label>
                                <div class="col-sm-9">
                                    <div class="form-check px-0 mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="recipients"
                                            id="tous"
                                            value="all"
                                            v-model="recipients"
                                            :data-vv-as="'destinataires'"
                                            v-validate="'required'"
                                            :class="{ 'is-invalid error': submitted && errors.has('recipients') }"
                                        >
                                        <label class="form-check-label" for="tous">tous</label>
                                    </div>
                                    <div class="form-check px-0 mb-3">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="recipients"
                                            id="specific"
                                            value="specific"
                                            v-model="recipients"
                                            :class="{ 'is-invalid error': submitted && errors.has('recipients') }"
                                        >
                                        <label class="form-check-label" for="specific">adresses spécifiques</label>
                                        <div class="invalid-feedback" v-if="submitted">
                                            {{ errors.first('recipients') }}
                                            <br><br>
                                        </div>
                                    </div>
                                    <vue-tags-input
                                        v-model="tag"
                                        :tags="tags"
                                        @tags-changed="newTags => tags = newTags"
                                        :placeholder="placeholder"
                                        :separators="[',']"
                                        @before-adding-tag="parseEmails"
                                        :disabled="recipients === 'all'"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9 my-3">
                            <div class="mb-3 row">
                                <label class="col-sm-3 col-form-label font-bold">Message <span class="obligatory">*</span></label>
                                <div class="col-sm-9">
                                    <textarea
                                        rows="8"
                                        cols="40"
                                        v-model="message"
                                        name="message"
                                        v-validate="'required'"
                                        class="message form-control"
                                        :class="{ 'is-invalid error': submitted && errors.has('message') }"
                                    >
                                    </textarea>
                                    <div class="invalid-feedback" v-if="submitted">
                                        {{ errors.first('message') }}
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end mt-4">
                                <button
                                    class="btn btn-primary px-5"
                                    @click.prevent='sendEmail()'
                                    :disabled="
                                        (message === '' || recipients === '' || subject === '' || (tag === '' && tags === [])) ||
                                        sendingEmail === true ||
                                        !canUpdate"
                                    >
                                    <div class="spinner-border spinner-border-sm" role="status" v-if="sendingEmail">
                                        <span class="visually-hidden">ENVOYER LE COURRIEL</span>
                                    </div>
                                    ENVOYER LE COURRIEL
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    </div>
</template>
<script>

// helper
import VueTagsInput from '@johmun/vue-tags-input';
import Alert        from '@/helpers/Alert.js';
import Vue          from 'vue';
import {
    mapActions,
    mapGetters
} from 'vuex';

export default {
    components: {
        VueTagsInput
    },
    data() {
        return {
            tag:             '',
            tags:            [],
            placeholderText: '',
            sendingEmail:    false,
            subject:         '',
            message:         '',
            recipients:      '',
            submitted:       false,
        };
    },
    computed: {
        ...mapGetters('core/auth', [
            'canUpdate'
        ]),

        placeholder () {
            return (this.tags.length === 0 ? "Inscrire une ou plusieurs adresses courriels" : null);
        },
        emailListCommaSeparated: function () {
            let valueToReturn = '';
            this.tags.forEach(tag => {
                valueToReturn += tag.text+',';
            });
            return valueToReturn;
        }
    },
    methods: {
        // Send email
        ...mapActions('core/email', {
            create: 'create'
        }),

        /**
         * Send email to recipients
         */
        sendEmail () {
            Vue.prototype.$validation = this.$validator;
            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.submitted = true;
                    this.loading = false;
                    return;
                }
                this.sendingEmail = true;
                this.create({
                    subject:     this.subject,
                    recipients:  this.recipients === 'all' ? 'all' : this.emailListCommaSeparated,
                    message:     this.message
                })
                .then(() => {
                    Alert.success('Courriel envoyé');
                    //clear the form
                    this.subject    = '';
                    this.recipients = '';
                    this.message    = '';
                    this.tag        = '';
                    this.tags       = [];
                })
                .finally(() => {
                    this.sendingEmail = false;
                    this.submitted = false;
                    this.loading= false;
                });
            });
        },

        /*
         * This method parses emails when user copy and paste in the field "Destinaires" and transform them to tags
         * email addresses sould be separated by any space separator
         */
        parseEmails() {
            let emails = this.tag.split(/(\s+)/);
            for(let email of emails) {
                if(email.trim()){
                    this.tags.push({text:email});
                }
            }
            this.tag='';
        }
    }
}
</script>
