<template>
    <div id="slide-form">
        <div v-if="type==='member'">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header p-4">
                    <h4 id="offcanvasRightLabel" v-if="model.id">Modifier un membre</h4>
                    <h4 id="offcanvasRightLabel" v-else>Ajouter un membre</h4>
                    <button type="button" data-bs-dismiss="offcanvas" ref="close" class="text-reset close-slide-form" aria-label="Close">
                        <font-awesome-icon :icon="['fas','arrow-right']" class="icon alt"/>
                    </button>
                </div>
                <div class="offcanvas-body p-4">
                    <form>
                        <div class="row mb-3">
                            <label for="member-first-name" class="col-sm-3 col-form-label">Prénom</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="modelTemp.firstName" class="form-control" id="member-first-name" @keyup="btnDisabledCopy = true">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="member-last-name" class="col-sm-3 col-form-label">Nom</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="modelTemp.lastName" class="form-control" id="member-last-name" @keyup="btnDisabledCopy = true">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="member-email" class="col-sm-3 col-form-label">Courriel</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="modelTemp.email" class="form-control" id="member-email" @keyup="btnDisabledCopy = true">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="member-types" class="col-sm-3 col-form-label">Type(s)</label>
                            <div class="col-sm-9">
                                <select class="form-select" multiple id="member-types" size="18" v-model="juries" @click="btnDisabledCopy = true">
                                    <option
                                        v-for="jury in listJuries"
                                        :value="jury.id"
                                        :key="jury.id"
                                    >
                                        {{jury.attributes.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="d-flex justify-content-end" v-if="model.id">
                        <button
                            type="button"
                            class="btn btn-link float-start delete"
                            @click.prevent="deleteMember(model.id)"
                            data-bs-dismiss="offcanvas"
                            :disabled="!canUpdate"
                        >
                            <font-awesome-icon :icon="['fas','times']" class="icon alt"/>
                            &nbsp;&nbsp;SUPPRIMER CE MEMBRE
                        </button>
                    </div>
                </div>
                <div class="d-flex justify-content-between canvas-footer p-3" v-if="!model.id">
                    <button type="button" class="btn btn-link float-start" data-bs-dismiss="offcanvas">ANNULER</button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="createNewMember()"
                        data-bs-dismiss="offcanvas"
                        :disabled="loading || !btnDisabledCopy || !canUpdate"
                    >
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                        SAUVEGARDER
                    </button>
                </div>
                <div class="d-flex justify-content-end canvas-footer p-3" v-else>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="updateMember(model.id)"
                         data-bs-dismiss="offcanvas"
                        :disabled="loading || !btnDisabledCopy || !canUpdate"
                    >
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                        METTRE À JOUR
                    </button>
                </div>
            </div>
        </div>
        <div v-if="type==='user'">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header p-4">
                    <h4 id="offcanvasRightLabel" v-if="model.id">Modifier un utilisateur</h4>
                    <h4 id="offcanvasRightLabel" v-else>Ajouter un utilisateur</h4>
                    <button type="button" data-bs-dismiss="offcanvas" ref="close" class="text-reset close-slide-form" aria-label="Close">
                        <font-awesome-icon :icon="['fas','arrow-right']" class="icon alt"/>
                    </button>
                </div>
                <div class="offcanvas-body p-4">
                    <form>
                        <div class="row mb-3">
                            <label for="user-first-name" class="col-sm-3 col-form-label">Prénom</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="model.firstName" class="form-control" id="user-first-name" @keyup="btnDisabledUser = true">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="user-last-name" class="col-sm-3 col-form-label">Nom</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="model.lastName" class="form-control" id="user-last-name" @keyup="btnDisabledUser = true">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="user-email" class="col-sm-3 col-form-label">Courriel</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="model.email" class="form-control" id="user-email" @keyup="btnDisabledUser = true">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="user-types" class="col-sm-3 col-form-label">Rôle</label>
                            <div class="col-sm-9">
                                <select v-model="model.role" class="form-select" id="user-types" @click="btnDisabledUser = true">
                                    <option value="admin">Admin</option>
                                    <option value="checker">Vérificateur</option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="d-flex justify-content-end pt-4" v-if="model.id">
                        <button type="button"
                            class="btn btn-link delete float-start"
                            @click.prevent="deleteUser(model.id)"
                            :disabled="!canUpdate"
                        >
                            <font-awesome-icon :icon="['fas','times']" class="icon alt"/>
                            &nbsp;&nbsp;SUPPRIMER CET UTILISATEUR
                        </button>
                    </div>
                </div>
                <div class="p-4" v-if="!model.id">
                    <p>
                        Au moment de la sauvegarde, l'utilisateur peut se connecter à la plateforme.
                    </p>
                    <p>
                        Veuillez lui communiquer le lien de connexion.
                    </p>
                </div>
                <div class="d-flex justify-content-between canvas-footer p-3" v-if="!model.id">
                    <button type="button" class="btn btn-link float-start" data-bs-dismiss="offcanvas">ANNULER</button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="createNewUser()"
                        data-bs-dismiss="offcanvas"
                        :disabled="loading || !btnDisabledUser || !canUpdate"
                    >
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                        SAUVEGARDER
                    </button>
                </div>
                <div class="d-flex justify-content-end canvas-footer p-3" v-else>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="updateUser(model.id)"
                         data-bs-dismiss="offcanvas"
                        :disabled="loading || !btnDisabledUser || !canUpdate"
                    >
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                        METTRE À JOUR
                    </button>
                </div>
            </div>
        </div>
        <div v-if="type==='candidacy'">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header p-4">
                    <h4 id="offcanvasRightLabel" v-if="model.id">Modifier une candidature</h4>
                    <h4 id="offcanvasRightLabel" v-else>Ajouter une candidature</h4>
                    <button type="button" data-bs-dismiss="offcanvas" ref="close" class="text-reset close-slide-form" aria-label="Close">
                        <font-awesome-icon :icon="['fas','arrow-right']" class="icon alt"/>
                    </button>
                </div>
                <div class="offcanvas-body p-4">
                    <form>
                        <div v-for="(label, index) in singleCategory.attributes.headings" :key="label" class="row mb-3">
                            <label :for="'candidacy-nomme-'+index" class="col-sm-3 col-form-label">{{ label }}</label>
                            <div class="col-sm-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    :id="'candidacy-nomme-'+index"
                                    :name="`label-${index}`"
                                    v-model="dataModels[label]"
                                    @change="activeNominationButton = true"
                                    :class="{ 'is-invalid': errors.has(`label-${index}`) }"
                                    v-validate="'required'"
                                    :data-vv-as="label"
                                >
                                <div class="invalid-feedback">
                                    {{ errors.first(`label-${index}`) }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <label for="candidacy-nomme-extract" class="col-sm-3 col-form-label">Extrait</label>
                            <div class="col-sm-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="candidacy-nomme-extract"
                                    v-validate="'url'"
                                    v-model="dataModels.extract"
                                    name="extract"
                                    :class="{ 'is-invalid': errors.has('extract') }"
                                >
                                <div class="invalid-feedback">
                                    {{ errors.first('extract') }}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="d-flex justify-content-between canvas-footer p-3" v-if="!model.id">
                    <button type="button" class="btn btn-link float-start" data-bs-dismiss="offcanvas">ANNULER</button>
                    <button type="button" class="btn btn-primary" @click="saveNominations()" :disabled="!activeNominationButton || loading">SAUVEGARDER</button>
                </div>
                <div class="d-flex justify-content-end canvas-footer p-3" v-else>
                    <button type="button" class="btn btn-primary">METTRE À JOUR</button>
                </div>
            </div>
        </div>
        <div v-if="type==='table'">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header p-4">
                    <h4 id="offcanvasRightLabel">Modifier le tableau</h4>
                    <button type="button" data-bs-dismiss="offcanvas" ref="close" class="text-reset close-slide-form" aria-label="Close">
                        <font-awesome-icon :icon="['fas','arrow-right']" class="icon alt"/>
                    </button>
                </div>
                <div class="offcanvas-body p-4 pb-0">
                    <div class="mb-5">
                        <p>
                            Vous pourrez changer les noms de colonnes. Si le champs est vide, la colonne sera enlevé du tableau.
                        </p>
                        <p>
                            Les colonnes 4, 5 et 6 ne sont pas éditables.
                        </p>
                        <p>
                            Maximum de 6 colonnes au total est permis.
                        </p>
                    </div>
                    <form>
                        <div class="row mb-4">
                            <label for="colonne-1" class="col-sm-3 col-form-label font-bold">Colonne 1</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="colonne-1" v-model="tableHeader.colonne0">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="colonne-2" class="col-sm-3 col-form-label font-bold">Colonne 2</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="colonne-2" v-model="tableHeader.colonne1">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="colonne-3" class="col-sm-3 col-form-label font-bold">Colonne 3</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="colonne-3" v-model="tableHeader.colonne2">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="colonne-4" class="col-sm-3 col-form-label font-bold">Colonne 4</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="colonne-4" readonly value="Extrait">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="colonne-5" class="col-sm-3 col-form-label font-bold">Colonne 5</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="colonne-5" readonly value="Résultats">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <label for="colonne-6" class="col-sm-3 col-form-label font-bold">Colonne 6</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="colonne-6" readonly value="Action">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="d-flex justify-content-between canvas-footer p-3">
                    <button type="button" class="btn btn-link float-start" data-bs-dismiss="offcanvas">ANNULER</button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="saveHeader()"
                        data-bs-dismiss="offcanvas"
                        :disabled="!canUpdate"
                    >
                        METTRE À JOUR
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '@/helpers/EventBus.js';
    import Alert        from '@/helpers/Alert.js';
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';

    export default {
        /**
         * This component is set to create a form to add user, member and candidacy
         * We can also modify and delete members by using this component
         *
         * @prop {string} type    The type of current form
         * Can be user, member or candidacy
         *
         * @prop {object} model  Member or user edited
         */
        props: {
            'type': {
                default: 'member',
                type:    String
            },
            'model': {
                default: () => {
                    return {
                        firstName: '',
                        lastName: '',
                        email: '',
                        types: []
                    }
                },
                type: Object
            },
            'categoryId':{
                type:    String
            },
            'btnDisabled' : {
                type: Boolean
            }
        },
        data () {
            return {
                tableHeader: {
                    colonne0: '',
                    colonne1: '',
                    colonne2: ''
                },
                category:{
                    headings: []
                },
                activeNominationButton: false,
                member: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    types: []
                },
                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    role: '',
                },
                loading: false,
                juries: [],
                btnDisabledCopy: this.btnDisabled,
                btnDisabledUser: this.btnDisabledUserForm,
                //Temporary object to save the form in it
                modelTemp: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    juryTypes: [],
                    role: '',
                }
            }
        },

        computed: {
            ...mapState('core/member', {
                listMembers: state => state.members
            }),
            ...mapState('core/jury', {
                listJuries: state => state.juries
            }),
            ...mapState('core/category', [
                'singleCategory'
            ]),
            ...mapGetters('core/auth', [
                'canUpdate'
            ]),

            /**
             * Prepare the v-models for the nominations
             */
            dataModels () {
                let data = {};
                this.singleCategory.attributes.headings.forEach(element => {
                    data[element] = null;
                });
                data.extract = null;

                return data;
            }
        },
        watch : {
            /**
             *
             */
            model () {
                this.juries = this.model.types;
            }
        },
        mounted() {
            let myOffcanvas = document.getElementById('offcanvasRight');

            //when the slide is shown do :
            myOffcanvas.addEventListener('shown.bs.offcanvas', () => {
                this.modelTemp.firstName   = this.model.firstName;
                this.modelTemp.lastName    = this.model.lastName;
                this.modelTemp.email       = this.model.email;
                this.modelTemp.types       = this.juries;
                this.modelTemp.role        = this.model.role;
            });

            //when it's hidden
            myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
                this.btnDisabledCopy = false;
                this.btnDisabledUser = false;
            });

            if (Object.keys(this.singleCategory).length !== 0 && this.singleCategory.attributes.headings !== null) {
                this.category.headings = this.singleCategory.attributes.headings;
                Object.keys(this.tableHeader).forEach((key, index) => {
                    this.tableHeader[key] = this.category.headings[index];
                });
            }
        },

        created() {
            this.modelTemp.firstName = this.model.firstName;
            this.modelTemp.lastName  = this.model.lastName;
            this.modelTemp.email     = this.model.email;
            this.modelTemp.types     = this.juries;
            this.modelTemp.role      = this.model.role;
        },

        methods: {
            ...mapActions('core/member', {
                create: 'create',
                delete: 'delete',
                update: 'update'
            }),

            ...mapActions('core/nominations', {
                createNominations: 'create'
            }),

            ...mapActions('core/category', {
                updateCategory: 'update',
            }),
            ...mapActions('core/admin', {
                createUserAction: 'create',
                updateUserAction: 'update',
                deleteUserAction: 'delete',
            }),

            /**
             * Edit Table header
             *
             * @return void
             */
            saveHeader() {
                this.category.headings = [];
                for (var title in this.tableHeader) {
                    if (this.tableHeader[title]){
                        this.category.headings.push(this.tableHeader[title]);
                    }
                }
                if (this.categoryId !== undefined) {
                    this.updateCategory({
                        id:         this.singleCategory.id,
                        attributes: this.category
                    })
                        .then(() => {
                            Alert.success('Catégorie mise à jour avec succès');
                            this.$emit('saveHeadings', this.category.headings);
                        })
                } else {
                    return;
                }
            },

            /**
             * Add nomination to the table
             *
             * @return void
             */
            saveNominations() {
                this.$validator.validate().then((valid) => {
                    if (!valid) {
                        return;
                    }

                    this.loading = true;
                    let headings = [];
                    this.singleCategory.attributes.headings.forEach(element => {
                        headings.push(this.dataModels[element]);
                    });

                    this.createNominations({
                        url:        this.dataModels.extract,
                        headings:   headings,
                        categoryId: this.singleCategory.id
                    })
                        .then(() =>{
                            Object.keys(this.dataModels).forEach(key => {
                                this.dataModels[key] = null;
                            });
                            Alert.success('La candidature ajouté avec succès');
                            this.$emit('addNominations');
                            this.activeNominationButton = false;
                            this.loading = false;
                            this.$refs.close.click();
                        });
                });
            },

            /**
             * Delete member
             * @param  {string} the id of the current model we're editing
             */
            deleteMember(id) {
                Alert.confirmation("Êtes-vous certain de vouloir supprimer le membre ?", "SUPPRIMER", '#E55A5A')
                    .then((response)=>{
                        if(response.isConfirmed) {
                            this.delete(id)
                                .then(() => {
                                    Alert.success('Membre supprimé avec succès');
                                })
                                .finally(() => {
                                    EventBus.$emit('refreshMembers');
                                    this.loading         = false;
                                    this.btnDisabledCopy = false;
                                });
                        }
                    });
            },

            /**
             * Delete user
             *
             * @param  {string} the id of the current model we're editing
             */
            deleteUser(id) {
                Alert.confirmation("Êtes-vous certain de vouloir supprimer l'utilisateur ?", "SUPPRIMER", '#E55A5A')
                    .then((response)=>{
                        if(response.isConfirmed) {
                            this.deleteUserAction(id)
                                .then(() => {
                                    // Popup with success
                                    Alert.success('Utilisateur supprimé avec succès');
                                })
                                .finally(() => {
                                    // Disable loading and the create button
                                    this.loading         = false;
                                    this.btnDisabledUser = false;
                                });
                        }
                    });
            },

            /**
             * Create new member
             *
             */
            createNewMember() {
                this.loading            = true;
                this.member.first_name  = this.modelTemp.firstName;
                this.member.last_name   = this.modelTemp.lastName;
                this.member.email       = this.modelTemp.email;
                this.member.types       = this.juries;
                this.create(this.member)
                .then(() => {
                    Alert.success('Membre ajouté avec succès');
                })
                .finally(() => {
                    EventBus.$emit('refreshMembers');
                    this.loading         = false;
                    this.btnDisabledCopy = false;
                });
            },

            /**
             * Update member we're editing
             *
             * @param  {string} the id of the current model we're editing
             */
            updateMember(id) {
                Alert.confirmation("Êtes-vous certain de vouloir modifier le membre ?", "MODIFIER", '#E55A5A')
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.loading            = true;
                            this.member.id          = id;
                            this.member.first_name  = this.modelTemp.firstName;
                            this.member.last_name   = this.modelTemp.lastName;
                            this.member.email       = this.modelTemp.email;
                            this.member.types       = this.juries;
                            this.update(this.member)
                            .then(() => {
                                Alert.success('Membre mis à jour avec succès');
                            })
                            .finally(() => {
                                EventBus.$emit('refreshMembers');
                                this.loading             = false;
                                this.btnDisabledCopy     = false;
                                this.modelTemp.firstName = this.member.first_name;
                                this.modelTemp.lastName  = this.member.last_name;
                                this.modelTemp.email     = this.member.email;
                                this.juries              = this.member.types;
                            });
                        }
                    })
            },

            /**
             * Create new user
             *
             */
            createNewUser() {
                this.loading          = true;
                this.user.first_name  = this.model.firstName;
                this.user.last_name   = this.model.lastName;
                this.user.email       = this.model.email;
                this.user.role        = this.model.role;
                this.createUserAction(this.user)
                .then(() => {
                    // Popup with success
                    Alert.success('Utilisateur ajouté avec succès');
                })
                .finally(() => {
                    // Disable loading and the create button
                    this.loading         = false;
                    this.btnDisabledUser = false;
                });
            },

            /**
             * Update user we're editing
             *
             * @param  {string} the id of the current model we're editing
             */
            updateUser(id) {
                Alert.confirmation("Êtes-vous certain de vouloir modifier l'utilisateur ?", "MODIFIER", '#E55A5A')
                    .then((response) => {
                        if (response.isConfirmed) {
                            this.loading            = true;
                            this.user.id          = id;
                            this.user.first_name  = this.model.firstName;
                            this.user.last_name   = this.model.lastName;
                            this.user.email       = this.model.email;
                            this.user.role        = this.model.role;
                            this.updateUserAction(this.user)
                            .then(() => {
                                // Popup with success
                                Alert.success('Utilisateur mis à jour avec succès');
                            })
                            .finally(() => {
                                // Disable loading and the create button
                                this.loading             = false;
                                this.btnDisabledUser     = false;
                                this.modelTemp.firstName = this.user.first_name;
                                this.modelTemp.lastName  = this.user.last_name;
                                this.modelTemp.email     = this.user.email;
                                this.modelTemp.role      = this.user.role;
                            });
                        }
                    })


            },
        }

    }
</script>
