<template>
    <div id="profile" class="d-flex justify-content-end py-4">
        <div class="dropdown">
            <button type="button" class="btn btn-profile d-flex" ref="openBtn" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="short-name me-2 d-flex justify-content-center">
                    <p class="m-0 font-bold align-self-center">{{ initials }}</p>
                </div>
                <font-awesome-icon :icon="['fas','angle-down']" class="align-self-center"/>
            </button>
            <div class="dropdown-menu dropdown-menu-end text-end font-regular" aria-labelledby="profileMenu">
                <div class="platforms pt-3">
                    <div class="header d-flex justify-content-end">
                        <h6 class="dropdown-header font-bold p-0 me-2 align-self-center">
                            Plateformes
                        </h6>
                        <font-awesome-icon :icon="['fas','angle-down']" class="align-self-center"/>
                    </div>
                    <router-link class="dropdown-item platform" :to="{ name: ' '}"> Rampe de lancement </router-link>
                    <router-link class="dropdown-item platform" :to="{ name: ' '}"> Académie </router-link>
                </div>
                <hr class="dropdown-divider m-0">
                <a class="dropdown-item" @click.prevent="logout()">Déconnexion</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import Alert                    from '@/helpers/Alert.js';
    export default {
        computed: {
            ...mapState('core/auth', [
                'user'
            ]),
            /*
             * Compute initials of user to display them on top of user menu.
             *
             */
            initials () {
                return this.user
                    ? this.user.attributes.first_name.charAt(0) + this.user.attributes.last_name.charAt(0)
                    : '';
            }
        },
        methods: {
            ...mapActions('core/auth', {
                logoutUser: 'logout'
            }),
            /**
             * Logout
             * Call logout function in api to destroy user cookies
             */
            logout () {
                this.logoutUser()
                .then(() => {
                    this.$session.destroy();
                    window.localStorage.clear();
                    this.$router.push({name:'login'});
                    Alert.success("Déconnexion réussie");
                });
            }
        }
    }

</script>
