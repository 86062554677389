<template>
    <div id="members">
        <h2 class='font-bold'>MEMBRES</h2>
        <div class="mt-3 p-2 px-3 filters">
            <div class="d-flex justify-content-end">
                <a href="#" class="m-0" @click.prevent="removeFilters()"> Enlever les filtres </a>
            </div>
            <form class="d-flex justify-content-between align-items-center">
                <div class="mb-3 flex-fill pe-5">
                    <label for="type" class="form-label font-bold">Type</label>
                    <select class="form-select" id="type" aria-label="Default select example" v-model="type">
                        <option value='all' selected>Tous</option>
                        <option value="member">Juste les membres</option>
                        <option value="juries">Juste les jurys</option>
                        <option v-for="jury in listJuries" :value="jury.id" :key="jury.id">{{jury.attributes.name}}</option>
                    </select>
                </div>
                <div class="form-check flex-fill pt-3">
                    <input class="form-check-input" type="checkbox" id="terminated-vote" v-model="done_voting">
                    <label class="form-check-label" for="terminated-vote">
                        N'ayant pas terminé de voter
                    </label>
                </div>
                <div class="flex-fill pt-3">
                    <search-input v-on:search="updateSearch"></search-input>
                </div>
                <div class="form-check pt-3">
                    <button class="btn btn-primary px-5" @click.prevent="activateFilter()">FILTRER</button>
                </div>
            </form>
        </div>
        <div class="d-flex mt-3">
            <div class="me-auto bold-label p-2 ps-0 font-bold"><span>{{ `Résultats (${blackTableData.length})` }}</span></div>
            <div class='pe-2'>
                <button class="btn btn-link"
                    @click="exportMembers()"
                    :disabled="!isExportButtonEnabled"
                >
                    <font-awesome-icon :icon="['fas','file-alt']" class="icon alt"/>
                    EXTRAIRE LES DONNÉES
                </button>
            </div>
            <button
                class="btn btn-link trigger-slide-form pe-0"
                type="button"
                @click="addNewMember()"
                :disabled="!canUpdate"
            >
                <font-awesome-icon :icon="['fas','plus']" class="icon alt"/>
                &nbsp; AJOUTER UN MEMBRE
            </button>
        </div>
        <slide-form :type="'member'" :model="memberToEdit" :btnDisabled="btnDisabled"></slide-form>
        <div class="d-flex justify-content-center pt-5" v-if="loading">
            <div class="spinner-border text-center" role="status">
                <span class="visually-hidden">Chargement en cours...</span>
            </div>
        </div>
        <black-table
            v-else
            :header="blackTableHeader"
            :data="blackTableData"
            v-on:editCurrentModel="showSlideForm($event)"
        ></black-table>
    </div>
</template>
<script>

import BlackTable     from '../layouts/BlackTable.vue';
import SlideForm      from '../layouts/SlideForm.vue';
import SearchInput    from '../layouts/SearchInput.vue';
import { EventBus }   from '@/helpers/EventBus.js';
import * as bootstrap from 'bootstrap';
import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';
import Alert from '@/helpers/Alert.js';

export default{
    components: {
        BlackTable,
        SlideForm,
        SearchInput
    },
    data() {
        return {
            blackTableHeader: [
                { id: 1, text: 'Nom',              position: 'start'   },
                { id: 2, text: 'Type',             position: 'start'   },
                { id: 3, text: 'Courriel',         position: 'start'   },
                { id: 4, text: 'Terminé de voter', position: 'center'  },
            ],
            memberToEdit: {},
            search: '',
            loading: false,
            type: 'all',
            done_voting: false,
            btnDisabled: false,
            isExportButtonEnabled: true,
        }
    },
    computed: {
        ...mapState('core/member', {
            listMembers: state => state.members
        }),
        ...mapState('core/jury', {
            listJuries: state => state.juries
        }),
        ...mapState('core/auth', [
            'user'
        ]),
        ...mapGetters('core/auth', [
            'canUpdate'
        ]),
        /**
         * Compute black table data from data coming from back-end
         *
         * @return {array} the data that would be injected in black table component
         */
        blackTableData () {
            let arrayToReturn = [];
            for(let memberId in this.listMembers){
                let member = this.listMembers[memberId];
                let element = {
                    id: member.id,
                    columns: [
                        { text: member.attributes.first_name+' '+member.attributes.last_name, position: 'start',  type: 'text' },
                        { text: this.addType(member),                                         position: 'start',  type: 'text' },
                        { text: member.attributes.email,                                      position: 'start',  type: 'text' },
                        { text: member.attributes.done_voting ? "Terminé":"----",             position: 'center', type: 'text' }
                    ]
                }
                arrayToReturn.push(element);
            }
            return arrayToReturn;
        },

        /**
         * Compute filters according to what user selected in the serach form
         *
         * @return {object} the filters that would be sent to the back-end
         */
        filters () {
            return {
                'type':        this.type        === ''    ? void 0 : this.type,
                'done_voting': this.done_voting === false ? void 0 : false,
                'search':      this.search      === ''    ? void 0 : this.search
            };
        },
        /**
         * Compute members in order to populate
         *
         */
        members () {
            let arrayMembersToReturn = [];
            for(let memberId in this.listMembers){
                let member = this.listMembers[memberId];
                let juries = [];
                for(let jury of member.relationships.juries.data){
                    juries.push(jury.id);
                }
                let element = {
                    id: member.id,
                    firstName: member.attributes.first_name,
                    lastName: member.attributes.last_name,
                    type: this.addType(member),
                    email: member.attributes.email,
                    types: juries
                }
                arrayMembersToReturn.push(element);
            }
            return arrayMembersToReturn;
        }
    },

    mounted() {
        this.loading = true;
        //Get list of members
        this.getMembers().then(() => {
            //Finish loading
            this.loading = false;
        });

        this.getJuries().then(()=>{});
        EventBus.$on('refreshMembers', () => {
            this.activateFilter();
        });
    },

    methods: {
        ...mapActions('core/member', {
            getMembers: 'index',
            exportMembersAction: 'exportMembers',
        }),
        ...mapActions('core/jury', {
            getJuries: 'index'
        }),

        /**
         * add type Membre and the name of the juries selected foreach member
         * @param {member} member object to access to attributes
         * @return string
         */
        addType(member) {
            let elements = member.relationships.juries.data;
            let arrayType = [];
            elements.forEach((item, i) => {
                if(this.listJuries[elements[i].id].attributes.name.toLowerCase !== "member") {
                    arrayType.push(this.listJuries[elements[i].id].attributes.name);
                }
            });
            arrayType = arrayType.sort(new Intl.Collator('fr').compare);
            arrayType.unshift('Membre');
            return arrayType.join(", ");
        },

        /**
         * initialize the member object to empty before displaying the canvas
         * when creating a new member
         */
        addNewMember(){
            this.memberToEdit = {
                firstName: '',
                lastName: '',
                email: '',
                types: [],
                type: ''
            };
            let myOffcanvas = document.getElementById('offcanvasRight');
            let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.show();
        },

        /**
         *
         * calls the export action
         */
        exportMembers(){
            Alert.confirmation(`Un document excel sera envoyé à ${this.user.attributes.email}`, "Extraire", '#E55A5A')
                .then((response) => {
                    if (response.isConfirmed) {
                        this.isExportButtonEnabled = false;
                        this.exportMembersAction().then(() => {
                            Alert.success(`Le document excel a été envoyé à ${this.user.attributes.email}`);
                        }).catch(() => {
                            Alert.fail("Erreur lors de l'exportaion des membres");
                        }).finally(() => {
                            this.isExportButtonEnabled = true;
                        })
                    }
                });
        },

        /**
         * Update the search input
         */
        updateSearch (search) {
            this.search = search;
        },

        /**
         * Show a model to edit on the slide form.
         *
         * @param   {string}   id    the id of the model to edit.
         * @return  void
         */
        showSlideForm (id) {
            this.memberToEdit = this.members.find(member => member.id === id);
            let myOffcanvas   = document.getElementById('offcanvasRight');
            let bsOffcanvas   = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.show();
        },

        /**
         * Activate search in categories list by changing a filter in the filters section
         * Set timout of 1 second to give time to user to type before making a ajax call
         */
        activateFilter () {
            this.loading = true;
            this.getMembers({
                filters: this.filters
            })
                .then(() => {
                    this.loading = false;
                });
        },
        /**
         * Remove all filters and send a request to get all members
         *
         */
        removeFilters () {
            this.type         = 'all';
            this.done_voting  = false;
            this.search       = '';
            EventBus.$emit('emptySearch');
            this.activateFilter();
        }
    }
}
</script>
