import Login            from '../components/core/security/Login.vue';
import NotFound         from '../components/core/errors/NotFound.vue';
import Ballot           from '../components/academie/forms/Ballot.vue';
import Categories       from '../components/academie/forms/categories/Categories.vue';
import Users            from '../components/academie/forms/Users.vue';
import SearchCategories from '../components/academie/forms/categories/Search.vue';
import FormCategories   from '../components/academie/forms/categories/Form.vue';
import Academie         from '../components/academie/Index.vue';
import EmailSender      from '../components/academie/forms/EmailSender.vue';
import Jury             from '../components/academie/forms/Jury.vue';
import Members          from '../components/academie/forms/Members.vue';
import PublicExport     from '../components/academie/forms/PublicExport.vue';

export default [
    {
        path:      '/',
        name:      'login',
        component: Login,
        meta:      {
            title: "Authentification"
        }
    },
    {
        path:      '/academie',
        name:      'academie',
        component: Academie,
        children: [
            {
                path:      'utilisateurs',
                name:      'users',
                component: Users,
                meta:      {
                    title: "Utilisateurs"
                }
            },
            {
                path:      'categories',
                name:      'categories',
                component: Categories,
                children: [
                    {
                        path:      'recherche',
                        name:      'search-categories',
                        component: SearchCategories,
                        meta:      {
                            title: "Rechercher les catégories"
                        },
                    },
                    {
                        path:      'formulaire',
                        name:      'form-categories',
                        component: FormCategories,
                        props:     true,
                        meta:      {
                            title: "Ajouter ou modifier une catégorie"
                        }
                    },
                ]
            },
            {
                path:      'membres',
                name:      'members',
                component: Members,
                meta:      {
                    title: "Membres"
                }
            },
            {
                path:      'scrutin',
                name:      'ballot',
                component: Ballot,
                meta:      {
                    title: "Scrutin"
                }
            },
            {
                path:      'envoie-courriels',
                name:      'send-emails',
                component: EmailSender,
                meta:      {
                    title: "Envoie de courriels"
                }
            },
            {
                path:      'jury',
                name:      'jury',
                component: Jury,
                meta:      {
                    title: "Jury"
                }
            },
            {
                path:      'public-extraire',
                name:      'public-export',
                component: PublicExport,
                meta:      {
                    title: "Utilisateurs"
                }
            },
        ]
    },
    {
        path:      '/:pathMatch(.*)*',
        name:      `not-found`,
        component: NotFound,
        meta:      {
            title: 'Page introuvable'
        }
    }
];
