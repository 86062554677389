import Api       from '@/helpers/Api.js';
import qs        from 'qs';
import normalize from 'json-api-normalizer';


const resource  = 'api/academie/v1/categories';

// Import mutations
import {
    ADD_CATEGORY,
    SET_CATEGORIES,
    SET_CATEGORY,
    UPDATE_CATEGORY,
    CATEGORY_INCLUDES
} from './mutation-types.js';

const state = () => ({
    categories:     {},
    singleCategory: {},
    included:       {}
});

// Getters
const getters = {
    /**
     * Get the  included nominations.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    nominations: (state) => {
        if (state.included === null) {
            return;
        }
        const categoryNomination = [];
        if (state.included !== undefined) {
            for (let i = 0; i < state.included.length; i++) {
                if (state.included[i].type === 'nominations') {
                    categoryNomination.push(state.included[i]);
                }
            }
        }

        // Sort with the first headings column
        return categoryNomination.sort(function (a, b) {
            return a.attributes.headings[0].localeCompare(b.attributes.headings[0]);
        });
    },

    /**
     * Get the included jury.
     *
     * @param      {Object}  state   The state
     *
     * @return     {Object}
     */
    jury: (state) => {
        if (state.included === null) {
            return;
        }
        return state.included.find(include => include.type === 'juries');
    },
};

// Actions
const actions = {
    /**
     * Get all categories.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   payload     The payload (filters)
     *
     * @return     {Promise}
     */
    index: (context,  payload) => {
        const urlParams = {
            include:   'jury',
            withCount: 'nominations',
        };

        if (payload) {
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }

        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    const includedData = response.data.included;
                    context.commit(SET_CATEGORIES, normalizedData);
                    context.commit(CATEGORY_INCLUDES, includedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Creates a category.
     *
     * @param      {Object}  context      The context
     * @param      {Object}  category     The category to be stored
     *
     * @return     {Promise}
     */
    create: (context, {category, juryId} ) => {
        return new Promise((resolve, reject) => {
            // We have to rename the criterias to new_criterias
            if(category.criterias) {
                category['new_criterias'] = [];
                category.criterias.forEach(criteria => category.new_criterias.push(criteria.name));
                delete category['criterias'];
            }
            let dataObject = {
                'data': {
                    'type':      'categories',
                    'attributes': category
                }
            }

            // Add the jury to relationships
            if (juryId !== undefined) {
                dataObject.data.relationships = {
                    "jury": {
                        "data": {}
                    }
                };

                dataObject.data.relationships.jury.data = juryId ? { 'id': juryId, 'type': 'juries'} : null;
            }

            Api.post(`${resource}`, dataObject)
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(ADD_CATEGORY, normalizedData.categories);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });

        });
    },

    /**
     * Update a category.
     *
     * @param      {Object}  context      The context
     * @param      {Object}  id           The id of category to be updated
     * @param      {Object}  category     The category to be updated
     *
     * @return     {Promise}
     */
    update: (context, { id, attributes, juryId }) => {
        const urlParams = {
            include: 'jury'
        };

        // We have to rename the criterias to new_criterias
        if(attributes.criterias) {
            attributes['new_criterias'] = [];
            attributes.criterias.forEach(criteria => attributes.new_criterias.push(criteria.name));
            delete attributes['criterias'];
        }
        // We should delete created_at and updated_at because we're not supposed to send them
        delete attributes['created_at'];
        delete attributes['updated_at'];

        let dataObject = {
            'data': {
                'type':       'categories',
                'id':         id,
                'attributes': attributes
            }
        }

        // Add the jury to relationships
        if (juryId !== undefined) {
            dataObject.data.relationships = {
                "jury": {
                    "data": {}
                }
            };

            dataObject.data.relationships.jury.data = juryId ? { 'id': juryId, 'type': 'juries'} : null;
        }

        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, dataObject, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    const includedData = response.data.included;
                    context.commit(UPDATE_CATEGORY, { id, ...normalizedData.categories[id] });
                    context.commit(CATEGORY_INCLUDES, includedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get single category.
     *
     * @param      {Object}   context     The context
     * @param      {string}   payload     The category identifier
     *
     * @return     {Promise}
     */
    getSingleCategory: (context, { id }) => {
        const urlParams = {
            include: 'nominations,jury'
        };
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/${id}`, {
                params: urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    const includedData = response.data.included;
                    context.commit(SET_CATEGORY, normalizedData.categories[id]);
                    context.commit(CATEGORY_INCLUDES, includedData);
                    resolve(normalizedData);
                    resolve(includedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Export all Categories with the vote results
     *
     * @return     {Promise}
     */
    exportCategories: () => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/export`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Init single category.
     *
     * @return  void
     */
    initSingleCategory: (context) => {
        context.commit(SET_CATEGORY, {});
        context.commit(CATEGORY_INCLUDES, {});
    }
};

// Mutations
const mutations = {
    [ADD_CATEGORY]: (state, payload) => {
        state.categories = {
            ...state.categories,
            ...payload.categories
        };
    },
    [SET_CATEGORIES]: (state, payload) => {
        state.categories = payload.categories;
    },
    [CATEGORY_INCLUDES]:(state, payload) => {
        state.included = (payload === undefined) ? null : payload;
    },
    [SET_CATEGORY]: (state, payload) => {
        state.singleCategory = payload;
    },
    [UPDATE_CATEGORY]: (state, payload) => {
        state.categories[payload.id] = { ...state.categories[payload.id], ...payload };
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
