<template>
    <div class="input-group search-group">
        <input type="text" class="form-control align-self-center" name="search" id="search" placeholder="Recherche" v-model="search" v-on:change="$emit('search', search);">
        <label for="search" class="input-group-text" id="basic-addon2">
            <font-awesome-icon :icon="['fas', 'search']" />
        </label>
    </div>
</template>
<script>

// Libraries
import { EventBus } from '@/helpers/EventBus.js';

/**
 * This component displays a search input with svg loop, since we have this input in two different places, we add it in a component.
 * It emit a search event with the data to search on the input change event.
 */
export default {
    data () {
        return {
            search: ''
        };
    },

    mounted () {
        EventBus.$on('initSearch', () => {
            this.search = '';
        })
    }
}
</script>>
