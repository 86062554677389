<template>
    <div id="users">
        <h2 class='font-bold'>UTILISATEURS</h2>
        <div class="my-3">
            <p>Les utilisateurs ci-dessous peuvent se connecter à cette plateforme.</p>
        </div>
        <div class="d-flex justify-content-end">
            <button
                class="btn btn-link trigger-slide-form"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                v-if="!hideAddNewUserBtn"
                @click="addNewUser()"
            >
                <font-awesome-icon :icon="['fas','plus']" class="icon alt"/>
                &nbsp;AJOUTER UN UTILISATEUR
            </button>
        </div>
        <SlideForm :type="'user'" :model="userToEdit" :btnDisabledUserForm="btnDisabledUserForm"></SlideForm>
        <div class="d-flex justify-content-center pt-5" v-if="loading">
            <div class="spinner-border text-center" role="status">
                <span class="visually-hidden">Chargement en cours...</span>
            </div>
        </div>
        <black-table
            :header="blackTableHeader"
            :data="blackTableData"
            v-on:editCurrentModel="showSlideForm($event)"
            v-else
        ></black-table>
    </div>
</template>

<script>
import BlackTable from '../layouts/BlackTable.vue';
import SlideForm  from '../layouts/SlideForm.vue';
import {mapActions, mapState} from 'vuex';
import * as bootstrap from 'bootstrap';

export default{
    components: {
        BlackTable,
        SlideForm
    },

    data() {
        return {
            blackTableHeader: [
                { id: 1, text: 'Nom',      position: 'start' },
                { id: 2, text: 'Rôle',     position: 'start' },
                { id: 3, text: 'Courriel', position: 'start' },
            ],
            userToEdit:             {},
            loading:                false,
            type:                   'all',
            btnDisabledUserForm:    false,
            hideAddNewUserBtn:      true
        }
    },
    computed: {
        ...mapState('core/admin', {
            listUsers: state => state.users
        }),

        /**
         * Compute black table data from data coming from back-end
         *
         * @return {array} the data that would be injected in black table component
         */
        blackTableData () {
            let arrayToReturn = [];
            for(let userId in this.listUsers){
                let user = this.listUsers[userId];
                let element = {
                    id: user.id,
                    columns: [
                        { text: `${user.attributes.firstName} ${user.attributes.lastName}`,      position: 'start',  type: 'text' },
                        { text: (user.attributes.role === 'checker') ? 'vérificateur' : 'admin', position: 'start',  type: 'text' },
                        { text: user.attributes.email,                                           position: 'start',  type: 'text' },
                    ]
                }
                arrayToReturn.push(element);
            }
            return arrayToReturn;
        },

        /**
         * Compute users in order to populate
         *
         */
        users () {
            let arrayUsersToReturn = [];
            for(let userId in this.listUsers){
                let user = this.listUsers[userId];
                let element = {
                    id: user.id,
                    firstName: user.attributes.firstName,
                    lastName: user.attributes.lastName,
                    role: (user.attributes.role === 'checker') ? 'vérificateur' : 'admin',
                    type: 'User',
                    email: user.attributes.email,
                }
                arrayUsersToReturn.push(element);
            }
            return arrayUsersToReturn;
        }
    },

    mounted() {
        this.loading = true;
        this.getUsers().then(() => {
            this.loading = false;
        });
    },

    methods: {

        ...mapActions('core/admin', {
            getUsers : 'index'
        }),

        /**
         * initialize the user object to empty before displaying the canvas
         * when creating a new user
         */
        addNewUser(){
            this.userToEdit = {
                firstName: '',
                lastName: '',
                email: '',
                role: '',
                type: '',
            };
            let myOffcanvas = document.getElementById('offcanvasRight');
            let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.show();
        },
        /**
         * Show a model to edit on the slide form.
         *
         * @param   {string}   id    the id of the model to edit.
         * @return  void
         */
        showSlideForm (id) {
            this.userToEdit = this.users.find(user => user.id === id);
            let myOffcanvas = document.getElementById('offcanvasRight');
            let bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.show();
        },
    },
}
</script>
