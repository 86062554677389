<template>
    <div id="ballot">
        <div class="header mb-4">
            <h2 class="font-bold"> SCRUTIN </h2>
        </div>
        <div class="started-ballot gold-border col-10 p-2 text-center" v-if="ballotStarted">
            <img src="@/assets/images/ballot_start.png" alt="">
            <h4 class="font-bold mt-2 mb-0">
                 --- Les votes sont présentement ouverts ---
            </h4>
        </div>
        <div class="form col-10 mb-5 mt-5">
            <div class="row">
                <div class="col-2 align-self-center mb-4">
                    <label for="name" class="font-bold"> Nom <span class="obligatory">*</span></label>
                </div>
                <div class="col-10 align-self-center mb-4">
                    <input
                        type="text"
                        class="form-control"
                        name="name"
                        id="name"
                        placeholder="ex: Test de validation, Premier tour, vote spécial, ect"
                        :disabled="ballotStarted"
                        v-model="name"
                        v-validate="'required'"
                        :class="{ 'is-invalid': submitted && errors.has('name') }"
                    >
                    <div class="invalid-feedback" v-if="submitted">
                        {{ errors.first('name') }}
                    </div>
                </div>
                <div class="col-2 align-self-center mb-4">
                    <label for="start-date" class="font-bold"> Date de début </label>
                </div>
                <div class="col-5 align-self-center mb-4">
                    <input
                        type="date"
                        class="form-control"
                        name="start-date"
                        id="start-date"
                        v-model="startDate"
                    >
                </div>
                <div class="col-5 align-self-center mb-4">
                    <input
                        type="time"
                        class="form-control"
                        name="start-time"
                        id="start-time"
                        v-model="startTime"
                    >
                </div>
                <div class="col-2 align-self-center">
                    <label for="end-date" class="font-bold"> Date prévue de fin </label>
                </div>
                <div class="col-5 align-self-center">
                    <input
                        type="date"
                        class="form-control"
                        name="end-date"
                        id="end-date"
                        v-model="endDate"
                    >
                </div>
                <div class="col-5 align-self-center">
                    <input
                        type="time"
                        class="form-control"
                        name="end-time"
                        id="end-time"
                        v-model="endTime"
                    >
                </div>
            </div>
            <div class="d-flex justify-content-end mt-1">
                <div class="col-10">
                    <p class="m-0 ps-2">
                        *aucune action automatique sera engendrée par le système lorsque la date et heure seront atteints.
                    </p>
                </div>
            </div>
        </div>
        <div class="ballot-status col-11 p-4" :class="ballotStarted ? 'pink-border' : 'gold-border'">
            <h5 class="font-bold"> {{ ballotStarted ? 'Arrêter le scrutin' : 'Démarrer le scrutin' }} </h5>
            <p> Les actions ci-dessous seront enclenchées par
                <span v-if="!ballotStarted"> le démarrage d'un scrutin: </span>
                <span v-else> l'arrêt du scrutin: </span>
            </p>
            <div class="list ps-4">
                <ul v-if="!ballotStarted">
                    <li> L'interface des membres acceptera les connexions. </li>
                    <li> Le champs Nom ci-haut ne sera pas modifiable. </li>
                    <li> La possibilité de modifier les catégories et leurs contenus (nommés, type, etc) sera enlevé aux admins. </li>
                    <li> La modification d'un membre et la gestion des jurys dans les catégories seront toujours possible durant le scrutin. </li>
                </ul>
                <ul v-else>
                    <li> L'interface des membres n'acceptera plus les connexions. </li>
                    <li> Les admins pourront à nouveau modifier les catégories. </li>
                </ul>
            </div>
            <p v-if="!ballotStarted"> N'oubliez pas d'envoyer un courriel d'invitation à vos membres après avoir démarrer le scrutin. </p>
            <button
                class="btn btn-secondary mt-2 w-100"
                :class="{ 'pink-background': ballotStarted }"
                @click="updateBallot()"
                :disabled="!canUpdate"
            >
                <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                {{ ballotStarted ? 'Arrêter le scrutin' : 'Démarrer le scrutin' }}
            </button>
        </div>
    </div>
</template>
<script>

// Helpers
import Alert  from '@/helpers/Alert.js'
import moment from 'moment';
import Vue    from 'vue';
import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';

export default {
    components: {

    },

    data () {
        return {
            name:          '',
            startDate:     null,
            startTime:     null,
            endDate:       null,
            endTime:       null,
            loading:       false,
            submitted:     false
        };
    },
    computed: {
        ...mapState('core/ballot', [
            'currentBallot', 'ballotStarted'
        ]),

        ...mapGetters('core/auth', [
            'canUpdate'
        ]),

        plannedStartAt() {
            let planned     = null;
            this.startDate != null ? planned = this.startDate : null;
            this.startTime != null && this.startDate != null ? planned = `${planned}T${this.startTime}` : null;
            return planned != null ? moment(planned).format("YYYY-MM-DDTHH:mm:ssZ") : null;
        },
        plannedEndAt() {
            let planned      = null;
            this.endDate    != null ? planned = this.endDate : null;
            this.endTime    != null && this.endDate != null ? planned = `${planned}T${this.endTime}` : null;
            return planned  != null ? moment(planned).format("YYYY-MM-DDTHH:mm:ssZ") : null;
        }
    },
    methods: {
        ...mapActions('core/ballot', {
            index:            'index',
            create:           'create',
            start:            'start',
            stop:             'stop',
            getStartedBallot: 'getStartedBallot'
        }),

        /**
         * Start or Stop a ballot
         */
        updateBallot () {
            Vue.prototype.$validation = this.$validator;
            this.$validator.validate().then((valid) => {
                if (!valid) {
                    this.submitted = true;
                    this.loading = false;
                    return;
                }
                if (this.ballotStarted) {
                    Alert.confirmation("Êtes-vous certain de vouloir arrêter le scrutin?", "ARRÊTER LE SCRUTIN", '#E55A5A', require('@/assets/images/ballot_stop.png'), '#E55A5A78')
                        .then((response) => {
                            if (response.isConfirmed) {
                                this.loading = true;
                                this.stop(Object.values(this.currentBallot)[0].id)
                                    .then(() => {
                                        Alert.success('Scrutin arrêté avec succès');
                                        this.name       = "";
                                        this.startDate  = null;
                                        this.startTime  = null;
                                        this.endDate    = null;
                                        this.endTime    = null;
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            }
                        });
                }
                else
                {
                    Alert.confirmation("Êtes-vous certain de vouloir démarrer le scrutin?", "DÉMARRER LE SCRUTIN", '#CBA361', require('@/assets/images/ballot_start.png'), '#CBA36178')
                        .then((response) => {
                            if (response.isConfirmed) {
                                let ballot = {
                                    name:             this.name,
                                    planned_start_at: this.plannedStartAt,
                                    planned_end_at:   this.plannedEndAt
                                };
                                this.loading = true;
                                this.create(ballot)
                                    .then((response) => {
                                        // Once ballot is created, we have to start it !
                                        this.start(Object.values(response)[0].id)
                                            .then(() => {
                                                Alert.success('Scrutin créé avec succès');
                                            })
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                        this.submitted = false;
                                    });
                            }
                        });
                }
            });
        }
    },

    mounted(){
        this.index();
        this.getStartedBallot()
            .then(() => {
                if(Object.keys(this.currentBallot).length !== 0) {
                    this.name = Object.values(this.currentBallot)[0].attributes.name;
                    this.startDate = moment(Object.values(this.currentBallot)[0].attributes.plannedStartAt).format("YYYY-MM-DD");
                    this.startTime = moment(Object.values(this.currentBallot)[0].attributes.plannedStartAt).format("HH:mm:ss");
                    this.endDate   = moment(Object.values(this.currentBallot)[0].attributes.plannedEndAt).format("YYYY-MM-DD");
                    this.endTime   = moment(Object.values(this.currentBallot)[0].attributes.plannedEndAt).format("HH:mm:ss");
                }
            });
    }
}
</script>
