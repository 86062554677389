import Vue from 'vue';

// We will use VeeValidate for the validation of forms.
import fr                         from 'vee-validate/dist/locale/fr';
import VeeValidate, { Validator } from 'vee-validate';

// Use VeeValidate
Vue.use(VeeValidate);

// Install and Activate the franch locale.
Validator.localize('fr', fr);

// All Validator extends to be used on forms.
const methods = [

];

methods.forEach(method => {
    Validator.extend(method.name, {
        validate: function (value) {
            return method.strongRegex.test(value);
        }
    });
});
