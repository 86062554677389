<template>
    <div id="search-categories">
        <div class="header mb-4">
            <h2 class="font-bold"> CATÉGORIES </h2>
        </div>
        <div class="search-section mb-3">
            <div class="text-end">
                <router-link :to="{ name: ''}" class="m-0" @click.native="initFilters()"> Enlever les filtres </router-link>
            </div>
            <div class="filters row">
                <div class="col-6 pe-lg-0">
                    <div class="col-12 mb-1">
                        <label class="section-label font-bold" for="who-can-vote">Qui peut voter</label>
                    </div>
                    <div class="col-12 d-flex">
                        <div class="col-lg-7 col-xl-8 pe-lg-1">
                            <select class="form-select" aria-label="Qui peut voter" id="who-can-vote" name="who-can-vote" v-model="voter">
                                <option value="null" selected>---</option>
                                <option value="all">Membre et jury</option>
                                <option value="members">Seulement les membres </option>
                                <option value="juries">Seulement les jurys</option>
                                <option :value="jury.id" v-for="jury in juries" :key="jury.id"> {{ jury.attributes.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-5 col-xl-4 d-flex justify-content-center">
                            <input
                                type="checkbox"
                                class="form-check-input align-self-center m-0 me-2"
                                name="special-category"
                                id="special-category"
                                v-model="special"
                            >
                            <label class="form-check-label align-self-center" for="special-category"> Catégorie spéciale </label>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="col-12 mb-1">
                        <label class="section-label font-bold" for="results">Résultats</label>
                    </div>
                    <div class="col-12 d-flex">
                        <div class="col-lg-3 col-xl-4 pe-lg-1 pe-xl-3">
                            <select class="form-select" id="results" v-model="results">
                                <option value="all" selected>Tous</option>
                                <option value="uncertain">incertain</option>
                                <option value="certain">certain</option>
                            </select>
                        </div>
                        <div class="col-6 px-lg-1 px-xl-2">
                            <search-input v-on:search="search = $event"></search-input>
                        </div>
                        <div class="col-lg-3 col-xl-2 ps-2 text-end">
                            <button type="submit" class="btn btn-primary w-lg-auto w-100" @click.prevent="activateFilter()">
                                filter
                                <span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" hidden></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
            <div class="results text-start">
                <p class="m-0"> {{ `Résultats (${blackTableData.length})` }} </p>
            </div>
            <div class="links d-flex">
                <div class="extract-data me-5 d-flex">
                    <router-link :to="{ name: '' }" class="m-0 d-flex" @click.native="extractCategories()">
                        <font-awesome-icon :icon="['fas','file-alt']" class="align-self-center me-2 icon alt" />
                        <p class="m-0 align-self-center font-bold"> extraire les données </p>
                    </router-link>
                </div>
                <div class="add-data d-flex">
                    <router-link
                        :to="{ name: '' }"
                        class="m-0 d-flex"
                        v-if="!ballotStarted && canUpdate"
                        @click.prevent.native="emptyCurrentCategory()"
                    >
                        <font-awesome-icon :icon="['fas','plus']" class="plus align-self-center me-2 icon alt"/>
                        <p class="m-0 align-self-center font-bold"> ajouter une catégorie </p>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center pt-5" v-if="loading">
            <div class="spinner-border text-center" role="status">
                <span class="visually-hidden">Chargement en cours...</span>
            </div>
        </div>
        <black-table
            v-else
            :header="blackTableHeader"
            :data="blackTableData"
            v-on:editCurrentModel="showSlideForm($event)"
        ></black-table>
    </div>
</template>
<script>

// Components
import BlackTable  from '../../layouts/BlackTable.vue';
import SearchInput from '../../layouts/SearchInput.vue';

import Alert        from '@/helpers/Alert.js';
import { EventBus } from '@/helpers/EventBus.js';
import {
    mapActions,
    mapState,
    mapGetters
} from 'vuex';

export default {
    components: {
        BlackTable,
        SearchInput
    },

    data () {
        return {
            voter:            null,
            special:          null,
            results:          'all',
            search:           null,
            loading:          true,
            blackTableHeader: [
                { id: 1, text: 'Spéciale',               position: 'center' },
                { id: 2, text: 'Titre de la catégorie',  position: 'start'  },
                { id: 3, text: 'Type de vote',           position: 'start'  },
                { id: 4, text: 'Qui peut voter',         position: 'start'  },
                { id: 5, text: 'Nombre de candidatures', position: 'start'  },
                { id: 6, text: 'Résultats',              position: 'center' }
            ]
        };
    },

    mounted () {
        // Start loading from the api.
        this.loading = true;

        // Get list of contacts
        this.index().then(() => {
            // Finish loading
            this.loading = false;
        });

        this.getBallots();
        this.getJuries();
    },

    computed: {
        ...mapState('core/category', [
            'categories'
        ]),

        ...mapState('core/ballot', [
            'ballotStarted'
        ]),

        ...mapGetters('core/auth', [
            'canUpdate'
        ]),

        ...mapState('core/auth', [
            'user'
        ]),

        ...mapState('core/jury', [
            'juries'
        ]),

        /**
         * Compute black table data from data coming from back-end
         *
         * @return {array} the data that would be injected in black table component
         */
        blackTableData () {
            let arrayToReturn = [];
            for (let categoryId in this.categories) {
                let category = this.categories[categoryId];
                let element = {
                    id:              category.id,
                    marked:          category.attributes.special,
                    checkboxChecked: category.attributes.results_final
                };
                let votingType = '';
                let nominationsCount = category.relationships.nominations.meta.count ? category.relationships.nominations.meta.count : 0;
                switch (category.attributes.voting_type) {
                    case 'one_choice':
                        votingType = 'un seul vote';
                        break;
                    case 'criterias':
                        votingType = 'par critère';
                        break;
                    default:
                        votingType = '3 choix';
                }
                element.columns = [
                    { text: null,                         position: 'center', type: 'star'    },
                    { text: category.attributes.name,     position: 'start',  type: 'text'    },
                    { text: votingType,                   position: 'center', type: 'text'    },
                    { text: this.peopleVoting(category),  position: 'start',  type: 'text'    },
                    { text: nominationsCount,             position: 'end',    type: 'text'    },
                    { text: null,                         position: 'center', type: 'checkbox'},
                ]
                arrayToReturn.push(element);
            }
            return arrayToReturn;
        },

        /**
         * Compute filters according to what user selected in the serach form
         *
         * @return {object} the filters that would be sent to the back-end
         */
        filters () {
            return {
                'special': this.special ? true : void 0,
                'results': this.results === null || this.results === 'all'  ? void 0 : this.results,
                'voter':   this.voter   === null ? void 0 : this.voter,
                'search':  this.search  === null || this.search  === '' ? void 0 : this.search
            };
        }
    },

    methods: {
        ...mapActions('core/category', [
            'index',
            'getSingleCategory',
            'initSingleCategory',
            'exportCategories'
        ]),

        ...mapActions('core/ballot', {
            getBallots: 'index',
        }),

        ...mapActions('core/jury', {
            getJuries: 'index',
        }),

        /**
         * Export all Categories with the vote results
         *
         * @return  void
         */
        extractCategories () {
            Alert.confirmation(`Un document excel sera envoyé à ${this.user.attributes.email}`, "Extraire", '#E55A5A')
                .then((response) => {
                    if(response.isConfirmed) {
                        this.exportCategories()
                            .then(() => {
                                Alert.success(`Le document excel a été envoyé à ${this.user.attributes.email}`);
                            });
                    }
                });
        },

        /**
         * Add to people voting, the name of the jury selected foreach category
         *
         * @param {Object} category : to access to attributes and relationships of the category selected
         * @return String Membre, jury name or both
         */
        peopleVoting (category) {
            let juryId = category.attributes.available_jury ? category.relationships.jury.data.id : null;
            let peopleToVote;

            if (category.attributes.available_jury && category.attributes.available_members) {
                peopleToVote = `Membres, ${this.juries[juryId].attributes.name}`;
            } else {
                peopleToVote = category.attributes.available_jury ? this.juries[juryId].attributes.name : 'Membres';
            }
            return peopleToVote;
        },

        /**
        * Init the Filters inputs
        */
        initFilters () {
            this.voter   = null;
            this.special = null;
            this.results = 'all';
            this.search  = '';

            EventBus.$emit('initSearch');
            this.activateFilter();
        },

        /**
         * Show an model to edit on the slide form.
         *
         * @param   {string}   id    the id of the model to edit.
         * @return  void
         */
        showSlideForm (id) {
            this.getSingleCategory({ id }).then(() => {
                this.$router.push({ name:'form-categories'});
            })
        },

        /**
         * Update the search input
         *
         * @param    {string}   search
         * @return   void
         */
        updateSearch (search) {
            this.search = search;
        },

        /**
         * Activate search in categories list by changing a filter in the filters section
         * Set timout of 1 second to give time to user to type before making a ajax call
         */
        activateFilter () {
            this.loading      = true;
            this.index({
                filters: this.filters
            })
                .then(() => {
                    this.loading = false;
                });
        },

        /**
         * Empty the singleCategory in the state,
         * in order to empty the category form when we add a new category
         *
         */
        emptyCurrentCategory () {
            this.initSingleCategory().then(() => {
                this.$router.push({name:'form-categories'});
            });
        }
    }
}
</script>
