import Vue from 'vue';
import App from './App.vue';

// Import Router
import router from './libraries/VueRouter.js';

// Import session to save user informations
import VueSession from 'vue-session';

// Import validation
import validation from './libraries/VeeValidate.js';

// Sass
import './assets/scss/app.scss';

// Popover
import Popover from 'vue-js-popover'
Vue.use(Popover)

// Import vuex stores
import store from './store';

// Bootstrap
require('bootstrap');

// Font awesome
import { library }     from '@fortawesome/fontawesome-svg-core';
import { } from '@fortawesome/free-brands-svg-icons';
import { faTimes, faAngleDown, faStar, faPlus, faFileAlt, faArrowRight, faSearch, faPen } from '@fortawesome/free-solid-svg-icons';
import { } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faTimes, faAngleDown, faStar, faFileAlt, faPlus, faArrowRight, faSearch, faPen);

Vue.component('font-awesome-icon', FontAwesomeIcon);

// Session may persist between tabs and browser instances
Vue.use(VueSession, {
  persist: true
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router,
  validation
}).$mount('#app')
