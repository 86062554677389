import Api       from '@/helpers/Api.js';

const resource  = '/api/academie/v1/messages';

// Import mutations
import { } from './mutation-types.js';

const state = () => ({

});

// Getters
const getters = {
};

// Actions
const actions = {
    create: (context,  { subject, recipients, message }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type': 'messages',
                    'attributes': {
                        'subject': subject,
                        'to':      recipients,
                        'message': message
                    }
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {}

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
