<template>
    <div class="ml-5 text-center">
        <button @click="deleteCandidacy()" class="btn btn-link text-center pt-0" :disabled="ballotStarted || !canUpdate">
            <font-awesome-icon :icon="['fas','times']" class="delete-icon icon alt"/>
        </button>
    </div>
</template>

<script>

import Alert        from '@/helpers/Alert.js';
import { EventBus } from '@/helpers/EventBus.js';
import {
    mapState,
    mapGetters
} from 'vuex';

export default {
    props: ['index'],

    name: 'DeleteButton',

    computed: {
        ...mapState('core/ballot', [
            'ballotStarted'
        ]),
        ...mapGetters('core/auth', [
            'canUpdate'
        ]),
    },

    methods: {
        deleteCandidacy() {
            if (this.ballotStarted) {
                return;
            }

            Alert.confirmation("Êtes-vous certain de vouloir supprimer cette candidature?", "SUPPRIMER", '#E55A5A')
                .then((response) => {
                    if (response.isConfirmed) {
                        EventBus.$emit('deleteRow', (this.index));
                    }
                });
        }
    },
};
</script>
