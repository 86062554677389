import Api       from '@/helpers/Api.js';
import qs        from 'qs';
import normalize from 'json-api-normalizer';
const resource  = 'api/academie/v1/members';

// Import mutations
import {
    SET_MEMBERS
} from './mutation-types.js';

const state = () => ({
    members:      {},
    singleMember: {}
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Get all members.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   payload     The payload (filters)
     *
     * @return     {Promise}
     */
    index: (context,  payload) => {
        const urlParams = {};

        if (payload) {
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }
        urlParams.include = 'juries';
        return new Promise((resolve, reject) => {
            Api.get(resource, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then((response) => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    context.commit(SET_MEMBERS, normalizedData);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * Creates a new Member.
     *
     * @param      {Object}  context      The context
     * @param      {Object}  member     The member to be stored
     *
     * @return     {Promise}
     */
    create: (context, member) => {
        let juries = [];
        member.types.forEach((element) => {
            juries.push({"id":element, "type": "juries"});
        });
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`, {
                'data': {
                    'type': 'members',
                    'attributes': {
                        email: member.email,
                        first_name: member.first_name,
                        last_name: member.last_name,
                    },
                    "relationships": {
                        "juries": {
                            "data": juries
                        }
                    }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, { camelizeKeys: false });
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                })
        });
    },
    /**
     * Delete member by id
     *
     * @param      {Object}   context                  The context
     * @param      {string}   id                       The member id
     *
     * @return     {Promise}
     */
    delete: (context, id) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    /**
     * Update du membre
     *
     * @param      {Object}   context                  The context
     * @param      {string}   member                   The member
     *
     * @return     {Promise}
     */
    update: (context, member) => {
        let juries = [];
        member.types.forEach((element) => {
            juries.push({"id":element, "type": "juries"});
        });
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${member.id}`, {
                'data': {
                    'id': member.id,
                    'type': 'members',
                    'attributes': {
                        email: member.email,
                        first_name: member.first_name,
                        last_name: member.last_name,
                    },
                    "relationships": {
                        "juries": {
                            "data": juries
                        }
                    }
                }
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
        });
    },
    /**
     * Get members exportation excel.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   payload     The payload (filters)
     *
     * @return     {Promise}
     */
     exportMembers: (context,  payload) => {
        const urlParams = {};

        if (payload) {
            urlParams.filter = payload.filters ? payload.filters : void 0;
        }

        return new Promise((resolve, reject) => {
            Api.get(`${resource}/export`, {
                params:           urlParams,
                paramsSerializer: params => qs.stringify(params, {
                    encode: false
                })
            })
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

// Mutations
const mutations = {
    [SET_MEMBERS]: (state, payload) => {
        state.members = payload.members;
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
