import Swal from 'sweetalert2/dist/sweetalert2.js';

import 'sweetalert2/src/sweetalert2.scss';

export default {
    /**
     * Show fail alert
     *
     * @param    {string}   message   fail message
     *
     */
    fail (message) {
        Swal.fire({
            position:           'top-end',
            icon:               'error',
            title:              'Erreur',
            text:               message,
            showCloseButton:    true,
            allowEscapeKey:     false,
            showConfirmButton:  false,
            confirmButtonColor: '#0c5748',
            toast:              true,
            timer:              6000,
            timerProgressBar:   true,
        });
    },

    /**
     * Show success alert
     *
     * @param    {string}   message   success message
     *
     */
    success (message) {
        Swal.fire({
            position:           'top-end',
            icon:               'success',
            title:              'Succès',
            text:               message,
            showCloseButton:    true,
            allowEscapeKey:     false,
            confirmButtonColor: '#0c5748',
            showConfirmButton:  false,
            toast:              true,
            timer:              6000,
            timerProgressBar:   true
        });
    },

    /**
     * Show a confirm dialog alert
     *
     * @param    {string}   message                   confirm dialog
     * @param    {string}   confirmationButtonLabel   confirm button label
     * @param    {string}   confirmationButtonColor   confirm button color
     * @param    {string}   image                     Image to show in the popup
     * @param    {string}   withBackdrop              To update the backdrop color
     *
     */
    confirmation (message, confirmationButtonLabel, confirmationButtonColor, image, withBackdrop) {
        var data = {
            title:              "Confirmation requise",
            text:               message,
            imageUrl:           image,
            imageHeight:        77,
            imageAlt:           'Custom image',
            icon:               '',
            showCancelButton:   true,
            confirmButtonColor: confirmationButtonColor,
            cancelButtonColor:  '#FFFFFF',
            confirmButtonText:  confirmationButtonLabel,
            cancelButtonText:   'ANNULER',
            reverseButtons:      true,
            focusConfirm:        true,
            focusCancel:         false,
            showCloseButton:     true,
            padding:             '35px'
        }

        withBackdrop ? data.backdrop = `${withBackdrop} left top no-repeat` : null;

        return Swal.fire(data);
    },

    /**
     * Hide All alerts
     */
     close () {
        Swal.close();
    }
};
