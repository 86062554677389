import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';

const resource = '/api/academie/v1/nominations';

// Import mutations
import {
    SET_NOMINATIONS,
    ADD_NOMINATIONS,
    UPDATE_NOMINATIONS
} from './mutation-types.js';

// Initial state
const state = () => ({
    nominations: {}
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Creates a nominations.
     *
     * @param      {Object}  context       The context
     * @param      {String}  url           link
     * @param      {array}   headings      String array
     * @param      {String}  categoryId    Category Identifier
     *
     * @return     {Promise}
     */
    create: (context, { url, headings, categoryId, }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`,{
                'data': {
                    'type':       'nominations',
                    'attributes': {
                        'url': url,
                        'headings': headings
                    },
                    'relationships': {
                        'category': {
                            'data': {
                                'id': categoryId,
                                'type': 'categories'
                            }
                        }
                    }
                }
            })
                .then(response => {
                    const normalizedData = normalize(response.data, {camelizeKey: false});
                    context.commit(ADD_NOMINATIONS, normalizedData.nominations);
                    resolve(normalizedData);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /**
     * Get all nominations.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   payload     The payload (filters)
     *
     * @return     {Promise}
     */
    index: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`)
            .then((response) => {
                const normalizedData = normalize(response.data, {camelizeKey: false});
                context.commit(SET_NOMINATIONS, normalizedData.nominations);
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Update nomination a nominations.
     *
     * @param      {Object}  context       The context
     * @param      {String}  url           link
     * @param      {array}   headings      String array
     * @param      {string}  id            Nomination Identifier
     * @param      {String}  categoryId    Category Identifier
     *
     * @return     {Promise}
     */
    update: (context, { url, headings, id, categoryId }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`,{
                'data': {
                    'type': 'nominations',
                    'id':   id,
                    'attributes': {
                        'url':      url,
                        'headings': headings
                    },
                    'relationships': {
                        'category': {
                            'data': {
                                'id':   categoryId,
                                'type': 'categories'
                            }
                        }
                    }
                }
            })
            .then((response) => {
                const normalizedData = normalize(response.data, {camelizeKey: false});
                context.commit(UPDATE_NOMINATIONS, normalizedData.nominations);
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Delete specific nominations.
     *
     * @param      {Object}   context     The context
     * @param      {Object}   id          nominations Identefier (string)
     *
     * @return     {Promise}
     */
    delete: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {
    [SET_NOMINATIONS]: (state, payload) => {
        state.nominations = payload;
    },
    [ADD_NOMINATIONS]: (state, payload) => {
        state.nominations = {
            ...state.nominations,
            ...payload.nominations
        };
    },
    [UPDATE_NOMINATIONS]: (state, payload) => {
        state.nominations[payload.id] = { ...state.nominations[payload.id], ...payload }
    }
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
