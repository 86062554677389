<template>
    <div>
        <table class="table" id="gold-table">
            <thead>
                <tr>
                    <th v-for="title in header" :key="title.id" scope="col" :class="`text-${title.position}`">{{ title.text }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="line in data" :key="line.id">
                    <td v-for="(column, index) in line.columns"
                        :key="index"
                        :class="`text-${column.position}`"
                        @click="editModel(index, line.id, column.editable)"
                    >
                        <textarea
                            v-if="columnId === index && rowId === line.id"
                            @change="saveModification(line.id, line, column.type, column.text)"
                            @blur="hideInput()"
                            type="text"
                            v-model="column.text"
                            :id="`input-${index}-${line.id}`"
                            class="edit-cell form-control"
                        />
                        <p v-else ref="tableValue" class="m-0">
                            <span class="column-text">
                                {{ column.text }}
                            </span>
                        </p>
                    </td>
                    <td>
                        <delete-button :index="line.id"></delete-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

// Components
import DeleteButton from './DeleteButton.vue';

// Libraries
import { mapState, mapGetters } from 'vuex';

export default {
    props: {
        /**
         * This component is set to create a dynamic Table.
         * It has header and Data props:
         *
         * @prop     {Array, Object}     header    The data to set on the header of the table
         * @example  { text: (String) text to be shown, position: (String) Can be center, start or end }
         *
         * @prop     {Array, Object}      Data      The data to show in this dynamic table.
         * @example {
         *      columns:   (Array of objects) example: [
         *           {
         *              text:     (String)
         *              position: (String) Can be center, start or end
         *           },
         *      ]
         * }
         *
         */
        header: {
            type: [Array, Object]
        },
        data: {
            type: [Array, Object]
        }
    },
    data () {
        return {
            columnId: null,
            rowId:    ''
        };
    },

    components: {
        DeleteButton
    },

    computed: {
        ...mapState('core/ballot', [
            'ballotStarted'
        ]),

        ...mapGetters('core/auth', [
            'canUpdate'
        ]),
    },

    methods: {
        // Turn specific cell into input
        editModel(column, row, isEditable) {
            if (this.ballotStarted || !this.canUpdate || !isEditable) {
                return;
            }
            this.columnId = column;
            this.rowId    = row;
            let timer     = 100;
            setTimeout(() => {
                document.getElementById(`input-${this.columnId}-${this.rowId}`).focus();
            }, timer);
        },

        /**
         * Emit event when a cell in the Gold table is changed.
         *
         * @param  {string}   LineId        the line ID
         * @param  {integer}  line          the new line data
         * @param  {string}   type          the input type
         * @param  {string}   text          the input text
         * @retrun void
         */
        saveModification(LineId, line, type, text) {
            this.$emit('editTable', {
                LineId,
                line,
                type,
                text
            });

            this.hideInput();
        },

        /**
         * Hide the input
         */
        hideInput () {
            this.columnId = null;
            this.rowId    = '';
        }
    }
};
</script>
