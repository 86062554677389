// Repository.js
import axios            from 'axios';
import HttpErrorHandler from '../helpers/HttpErrorHandler.js';

// API link
axios.defaults.baseURL = process.env.VUE_APP_APIURL;
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    headers: {
        'Content-Type':    'application/vnd.api+json',
        'Accept':          'application/vnd.api+json',
        'Accept-Language': 'fr-CA'
    }
});

// Intercept the response in errors case, should be treated in httpErrorHandler first.
axiosInstance.interceptors.response.use(null, (error) => {
    HttpErrorHandler.checkHttpErrors(error);
    return Promise.reject(error);
});

export default axiosInstance;
