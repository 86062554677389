<template>
    <div id="jury">
        <h2 class='font-bold'>JURY</h2>
        <div class="d-flex justify-content-between my-4">
            <p>Les jurys ci-dessous représentent les options disponibles dans cette plateforme.</p>
            <button
                class="btn btn-maj me-4 text-white btn-update"
                :disabled="(!updateJury && !submitted) || loading || !canUpdate"
                @click="saveJuries()">
                <span v-if="submitted" class="spinner-border spinner-border-sm ml-1"></span> METTRE À JOUR</button>
        </div>
        <div class="mt-5">
            <form>
                <span v-if="loading" class="spinner-border spinner-border-sm ml-1"></span>
                <div
                    class="row d-flex justify-content-start mt-3"
                    v-for="(jury, index) in juryList"
                    :key="`jury-${index}`"
                    :class="{ 'align-items-center': !errors.has(`name-${index}`) }"
                >
                    <label class="col-sm-1 col-form-label font-bold">Jury {{ index+1 }}</label>
                    <div class="col-sm-4">
                        <input
                            type="text"
                            :name="`name-${index}`"
                            class="form-control"
                            v-model="jury.attributes.name"
                            @keyup="updateJury = jury.attributes.name !== '' && errors.items.length === 0"
                            v-validate="'required'"
                            :data-vv-as="'nom'"
                            :class="{ 'is-invalid': errors.has(`name-${index}`) }"
                        />
                        <div class="invalid-feedback" v-if="errors.has(`name-${index}`)">
                            {{ errors.first(`name-${index}`) }}
                        </div>
                    </div>
                    <div class="col-sm-1 ps-0" v-if="canUpdate" :class="{ 'pt-2': errors.has(`name-${index}`) }">
                        <font-awesome-icon
                            :icon="['fas','times']"
                            class="icon alt remove-jury-member"
                            role="button"
                            @click="removeJury(index)"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 offset-sm-1 ps-0">
                        <button class="btn btn-link mt-4" @click.prevent="addJury()" :disabled="!canUpdate">
                            <font-awesome-icon :icon="['fas','plus']" class="icon alt"/>&nbsp;&nbsp;AJOUTER UN JURY
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import HttpStatus from 'http-status-codes';
import Alert      from '@/helpers/Alert.js';
import {
    mapActions,
    mapState,
    mapGetters
} from 'vuex';

const cloneDeep = require('clone-deep');

export default {
    data () {
        return {
            juryList:   [],
            updateJury: false,
            loading:    true,
            submitted:  false
        };
    },

    computed: {
        ...mapState('core/jury', [
            'juries'
        ]),

        ...mapGetters('core/auth', [
            'canUpdate'
        ]),
    },

    mounted () {
        this.getJuries().then(() => {
            this.loading = false;
        });
    },

    watch: {
        /**
         * When juries change in the state, regenerate the array
         */
        juries () {
            this.juryList = Object.values(cloneDeep(this.juries));
        },
    },

    methods: {
        ...mapActions('core/jury', {
            getJuries:  'index',
            createJury: 'create',
            editJury:   'update',
            deleteJury: 'delete'
        }),

        /**
         * Remove member from jury members table
         *
         * @param   {int}  index  The jury index in the list
         */
        removeJury (index) {
            if (!this.canUpdate) {
                return;
            }

            // If index is passed, just remove from list (not saved yet)
            if (this.juryList[index].id === undefined) {
                this.juryList.splice(index, 1);
                return;
            }

            // If the identifier is passed, delete the jury
            Alert.confirmation("Êtes-vous certain de vouloir supprimer le membre de jury ?", "SUPPRIMER", '#E55A5A')
                .then((response) => {
                    if (response.isConfirmed) {
                        this.deleteJury({ id: this.juryList[index].id });
                    }
                })
                .catch((error) => {
                    Alert.fail(error.response.data.errors[0].detail);
                });
        },

        /**
         * Add jury to the list
         */
        addJury () {
            this.juryList.push({
                'attributes': {
                    'name': ''
                }
            });
        },

        /**
         * Save juries (either create or update them).
         */
        saveJuries () {
            this.$validator.validate().then((valid) => {
                if (!valid) {
                    return;
                }

                this.submitted = true;

                for (let i = 0; i < this.juryList.length; i++) {
                    // If the identifier is undefined, create the jury
                    if (this.juryList[i].id === undefined) {
                        this.createJury({
                            name: this.juryList[i].attributes.name
                        })
                            .then(() => {
                                this.updateJury = false;
                                Alert.success('Jury(s) ajouté(s) avec succès');
                            })
                            .catch((error) => {
                                const httpCodesHandled = [
                                    HttpStatus.FORBIDDEN,
                                    HttpStatus.UNAUTHORIZED
                                ];

                                if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                    Alert.fail(`Le nom "${this.juryList[i].attributes.name}" est déjà utilisé`);
                                    this.juryList = this.juryList.splice(0, i);
                                } else if (!httpCodesHandled.includes(error.response.status)) {
                                    Alert.fail(error.response.data.errors[0].detail);
                                }
                                this.updateJury = true;
                            })
                            .finally(() => {
                                this.loading    = false;
                                this.submitted  = false;
                            });
                    } else {
                        // If the jury name was not changed, do not update it
                        if (this.juries[this.juryList[i].id].attributes.name === this.juryList[i].attributes.name) {
                            continue;
                        }

                        // If the identifier is defined, update the jury
                        this.editJury({
                            id:   this.juryList[i].id,
                            name: this.juryList[i].attributes.name
                        })
                            .then(() => {
                                Alert.success('Jury Modifié avec succès');
                            })
                            .catch((error) => {
                                const httpCodesHandled = [
                                    HttpStatus.FORBIDDEN,
                                    HttpStatus.UNAUTHORIZED
                                ];

                                if (error.response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
                                    Alert.fail(`Le nom "${this.juryList[i].attributes.name}" est déjà utilisé`);
                                    this.getJuries();
                                } else if (!httpCodesHandled.includes(error.response.status)) {
                                    Alert.fail(error.response.data.errors[0].detail);
                                }
                            })
                            .finally(() => {
                                this.loading    = false;
                                this.updateJury = false;
                                this.submitted  = false;
                            })
                    }
                }
            });
        }
    }
}
</script>
