import Api       from '@/helpers/Api.js';
import normalize from 'json-api-normalizer';
import Vue       from 'vue';

const resource = '/api/academie/v1/juries';

// Import mutations
import {
    SET_JURIES,
    ADD_JURY,
    UPDATE_JURY,
    REMOVE_JURY
} from './mutation-types.js';

// Initial state
const state = () => ({
    juries: {}
});

// Getters
const getters = {
};

// Actions
const actions = {
    /**
     * Creates a jury.
     *
     * @param      {Object}  context      The context
     * @param      {String}  name         The name of the new jury
     *
     * @return     {Promise}
     */
    create: (context, { name }) => {
        return new Promise((resolve, reject) => {
            Api.post(`${resource}`,{
                'data': {
                    'type':       'juries',
                    'attributes': {
                        'name': name
                    }
                }
            })
            .then(response => {
                const normalizedData = normalize(response.data, {camelizeKey: false});
                context.commit(ADD_JURY, normalizedData.juries);
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Get all juries.
     *
     * @param      {Object}   context     The context
     *
     * @return     {Promise}
     */
    index: (context) => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}`)
            .then((response) => {
                const normalizedData = normalize(response.data, {camelizeKey: false});
                context.commit(SET_JURIES, normalizedData);
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Edit specific Jury name.
     *
     * @param      {Object}   context                  The context
     * @param      {string}   payload.id               The jury identifier
     * @param      {string}   payload.attributes.name  The jury name
     *
     * @return     {Promise}
     */
    update: (context, { id, name }) => {
        return new Promise((resolve, reject) => {
            Api.patch(`${resource}/${id}`, {
                'data': {
                    'type': 'juries',
                    'id': id,
                    'attributes': {
                      'name': name
                    }
                }
            })
            .then((response) => {
                const normalizedData = normalize(response.data, {camelizeKey: false});
                context.commit(UPDATE_JURY, { id, ...normalizedData.juries[id] });
                resolve(normalizedData);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },

    /**
     * Delete specific Jury name.
     *
     * @param      {Object}   context                  The context
     * @param      {string}   payload.id               The jury identifier
     *
     * @return     {Promise}
     */
    delete: (context, { id }) => {
        return new Promise((resolve, reject) => {
            Api.delete(`${resource}/${id}`)
            .then(() => {
                context.commit(REMOVE_JURY, id);
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
        })
    }
};

// Mutations
const mutations = {
    [SET_JURIES]: (state, payload) => {
        state.juries = payload.juries ? payload.juries : {};
    },
    [ADD_JURY]: (state, payload) => {
        state.juries[payload.id] = payload;
    },
    [UPDATE_JURY]: (state, payload) => {
        state.juries[payload.id] = { ...state.juries[payload.id], ...payload };
    },
    [REMOVE_JURY]: (state, id) => {
        Vue.delete(state.juries, id);
    },
};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
