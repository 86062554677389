import Api       from '@/helpers/Api.js';

const resource = '/api/academie/v1/votes';

// Import mutations
import {} from './mutation-types.js';

// Initial state
const state = () => ({});

// Getters
const getters = {};

// Actions
const actions = {
    /**
     * Export the public vote results
     *
     * @param      {Object}  context       The context
     *
     * @return     {Promise}
     */
    publicResultsExport: () => {
        return new Promise((resolve, reject) => {
            Api.get(`${resource}/public-results`)
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

// Mutations
const mutations = {};

// Export module
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
